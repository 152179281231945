import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** The `Iterable` scalar type represents an array or a Traversable with any kind of data. */
    Iterable: any;
    /** The `Upload` type represents a file to be uploaded in the same HTTP request as specified by [graphql-multipart-request-spec](https://github.com/jaydenseric/graphql-multipart-request-spec). */
    Upload: any;
};

export type Change = Node & {
    __typename?: 'Change';
    id: Scalars['ID'];
    _id: Scalars['Int'];
    revision: Revision;
    field: Scalars['String'];
    value?: Maybe<Scalars['String']>;
    originalValue?: Maybe<Scalars['String']>;
    preApplyValue?: Maybe<Scalars['String']>;
    applied: Scalars['Boolean'];
    rejected: Scalars['Boolean'];
};

export type Color = Node & {
    __typename?: 'Color';
    id: Scalars['ID'];
    _id: Scalars['Int'];
    name: Scalars['String'];
    color?: Maybe<Scalars['String']>;
    materials?: Maybe<MaterialCursorConnection>;
    sort: Scalars['Int'];
};

export type ColorMaterialsArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    isDeleted: Scalars['Boolean'];
    materialGroups?: Maybe<Scalars['Iterable']>;
    id?: Maybe<Scalars['Int']>;
    id_list?: Maybe<Array<Maybe<Scalars['Int']>>>;
    name?: Maybe<Scalars['String']>;
    category?: Maybe<Scalars['String']>;
    category_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    scopes?: Maybe<Scalars['String']>;
    scopes_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    locations?: Maybe<Scalars['String']>;
    locations_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    keyFigures?: Maybe<Scalars['String']>;
    keyFigures_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    properties?: Maybe<Scalars['String']>;
    properties_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    colors?: Maybe<Scalars['String']>;
    colors_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    active?: Maybe<Scalars['Boolean']>;
};

export type ColorFilter_Order = {
    sort?: Maybe<Scalars['String']>;
};

export type Group = Node & {
    __typename?: 'Group';
    id: Scalars['ID'];
    _id: Scalars['Int'];
    name: Scalars['String'];
    description: Scalars['String'];
    externalLink: Scalars['String'];
    users?: Maybe<UserCursorConnection>;
};

export type GroupUsersArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    email_list?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Cursor connection for Group. */
export type GroupCursorConnection = {
    __typename?: 'GroupCursorConnection';
    edges?: Maybe<Array<Maybe<GroupEdge>>>;
    pageInfo: GroupPageInfo;
    totalCount: Scalars['Int'];
};

/** Edge of Group. */
export type GroupEdge = {
    __typename?: 'GroupEdge';
    node?: Maybe<Group>;
    cursor: Scalars['String'];
};

/** Information about the current page. */
export type GroupPageInfo = {
    __typename?: 'GroupPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    startCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
};

export type KeyFigure = Node & {
    __typename?: 'KeyFigure';
    id: Scalars['ID'];
    _id: Scalars['Int'];
    name: Scalars['String'];
    symbol: Scalars['String'];
    unit: Scalars['String'];
    category: KeyFigureCategory;
    type: Scalars['String'];
    placeholderText?: Maybe<Scalars['String']>;
    sort: Scalars['Int'];
};

export type KeyFigureCategory = Node & {
    __typename?: 'KeyFigureCategory';
    id: Scalars['ID'];
    _id: Scalars['Int'];
    name: Scalars['String'];
    keyFigures?: Maybe<Array<Maybe<KeyFigure>>>;
    formArea: Scalars['String'];
    additionalInformation?: Maybe<Scalars['String']>;
    sort: Scalars['Int'];
};

export type KeyFigureCategoryKeyFiguresArgs = {
    order?: Maybe<Array<Maybe<KeyFigureFilter_Order>>>;
};

export type KeyFigureCategoryFilter_Order = {
    sort?: Maybe<Scalars['String']>;
};

export type KeyFigureFilter_Order = {
    sort?: Maybe<Scalars['String']>;
};

export type KeyFigureValue = Node & {
    __typename?: 'KeyFigureValue';
    id: Scalars['ID'];
    _id: Scalars['Int'];
    lowerValue: Scalars['Float'];
    upperValue?: Maybe<Scalars['Float']>;
    material: Material;
    keyFigure: KeyFigure;
};

export type KeyFigureValueFilter_Order = {
    keyFigure_sort?: Maybe<Scalars['String']>;
    keyFigure_category_sort?: Maybe<Scalars['String']>;
};

export type Location = Node & {
    __typename?: 'Location';
    id: Scalars['ID'];
    _id: Scalars['Int'];
    name: Scalars['String'];
    parent?: Maybe<Location>;
    children?: Maybe<Array<Maybe<Location>>>;
    materials?: Maybe<MaterialCursorConnection>;
    sort: Scalars['Int'];
};

export type LocationChildrenArgs = {
    order?: Maybe<Array<Maybe<LocationFilter_Order>>>;
    exists?: Maybe<Array<Maybe<LocationFilter_Exists>>>;
};

export type LocationMaterialsArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    isDeleted: Scalars['Boolean'];
    materialGroups?: Maybe<Scalars['Iterable']>;
    id?: Maybe<Scalars['Int']>;
    id_list?: Maybe<Array<Maybe<Scalars['Int']>>>;
    name?: Maybe<Scalars['String']>;
    category?: Maybe<Scalars['String']>;
    category_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    scopes?: Maybe<Scalars['String']>;
    scopes_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    locations?: Maybe<Scalars['String']>;
    locations_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    keyFigures?: Maybe<Scalars['String']>;
    keyFigures_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    properties?: Maybe<Scalars['String']>;
    properties_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    colors?: Maybe<Scalars['String']>;
    colors_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    active?: Maybe<Scalars['Boolean']>;
};

export type LocationFilter_Exists = {
    parent?: Maybe<Scalars['Boolean']>;
};

export type LocationFilter_Order = {
    sort?: Maybe<Scalars['String']>;
};

export type Material = Node & {
    __typename?: 'Material';
    id: Scalars['ID'];
    _id: Scalars['Int'];
    active: Scalars['Boolean'];
    /** Autor des Materials */
    author?: Maybe<User>;
    /** Der letzte Benutzer hat das Material bearbeitet */
    lastEditor?: Maybe<User>;
    markedForDeletionAt?: Maybe<Scalars['String']>;
    /** Materialname */
    name: Scalars['String'];
    /** Wissenschaftliche Bezeichnungen */
    scientificName: Scalars['String'];
    /** Weitere Bezeichnungen */
    additionalNames: Scalars['String'];
    /** Materialuntergruppe, über parent property Zugriff auf Materialgruppe und Materialklasse */
    category: MaterialGroup;
    /** Anwendungsbereich */
    scopes?: Maybe<Array<Maybe<MaterialScope>>>;
    /** Anwendung, Ergänzung */
    additionalScopes: Scalars['String'];
    /** Beschreibung */
    description: Scalars['String'];
    /** Herkunft, Entstehung, Herstellung, geografisch */
    locations?: Maybe<Array<Maybe<Location>>>;
    /** Wachstum / Kultivierung */
    cultivation: Scalars['String'];
    /** Entstehung */
    origin: Scalars['String'];
    /** Gewinnung */
    extraction: Scalars['String'];
    /** Herstellung */
    production: Scalars['String'];
    /** Weitere Verarbeitung */
    specialProcessing: Scalars['String'];
    /** Revisionen: Nach Änderungen an Materialien werden neue Daten nicht sofort veröffentlicht sondern als Revision abgelegt. Diese können mit spezillen Berechtigungen freigegeben werden. */
    revisions?: Maybe<RevisionCursorConnection>;
    /** Kennzahlen (Mechanik / Thermodynamik) */
    keyFigures?: Maybe<Array<Maybe<KeyFigureValue>>>;
    /** Geruch */
    smell: Scalars['String'];
    /** Eigenschaften aus verschiedenen Bereichen (Optik, Struktur, Textur, Oberfläche / Haptik, Format / Dimension, Beständigkeit, ...) */
    properties?: Maybe<Array<Maybe<Property>>>;
    /** Farbbereich */
    colors?: Maybe<Array<Maybe<Color>>>;
    /** Farbbesonderheiten */
    additionalColorInformation: Scalars['String'];
    /** Dauerhaftigkeit */
    durability: Scalars['String'];
    /** Brandverhalten, Ergänzung */
    fireBehaviorAdditions: Scalars['String'];
    /** Lebensdauer nach BKI */
    durabilityBKI: Scalars['String'];
    /** Nutzungsdauer Jahre nach BNB */
    durabilityBNB: Scalars['String'];
    /** Risiko für die lokale Umwelt */
    localEnvironmentRisk: Scalars['String'];
    /** Ökobilanz, Bezug */
    ecologicalBalanceReference: Scalars['String'];
    /** Kreislauffähigkeit */
    recyclability: Scalars['String'];
    /** Anmerkungen zur Ökobilanz */
    ecologyAdditionalInformation: Scalars['String'];
    images?: Maybe<Array<Maybe<MaterialImage>>>;
    /** Verbindungstechniken, Ergänzung */
    specialCoupling: Scalars['String'];
    /** Mechanik */
    mechanic: Scalars['String'];
    /** Thermodynamik */
    thermodynamic: Scalars['String'];
    /** Beständigkeit */
    resistance: Scalars['String'];
    /** Gebrauchstechnik */
    usageTechnology: Scalars['String'];
    /** Akustik */
    acoustic: Scalars['String'];
    /** Sponsor BUW */
    sponsorBUW: Scalars['String'];
    /** Sponsor KIT */
    sponsorKIT: Scalars['String'];
    /** Sponsor MSA */
    sponsorMSA: Scalars['String'];
    /** Signatur BUW */
    signatureBUW: Scalars['String'];
    /** Signatur KIT */
    signatureKIT: Scalars['String'];
    /** Signatur MSA */
    signatureMSA: Scalars['String'];
    /** Copyright Bilddateien */
    copyrightImages: Scalars['String'];
    /** Copyright der Grafiken */
    copyrightGraphics: Scalars['String'];
    sort: Scalars['Int'];
    updatedAt?: Maybe<Scalars['String']>;
    isDeleted?: Maybe<Scalars['Boolean']>;
};

export type MaterialScopesArgs = {
    order?: Maybe<Array<Maybe<MaterialScopeFilter_Order>>>;
};

export type MaterialLocationsArgs = {
    order?: Maybe<Array<Maybe<LocationFilter_Order>>>;
    exists?: Maybe<Array<Maybe<LocationFilter_Exists>>>;
};

export type MaterialRevisionsArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    exists?: Maybe<Array<Maybe<RevisionFilter_Exists>>>;
};

export type MaterialKeyFiguresArgs = {
    order?: Maybe<Array<Maybe<KeyFigureValueFilter_Order>>>;
};

export type MaterialPropertiesArgs = {
    order?: Maybe<Array<Maybe<PropertyFilter_Order>>>;
};

export type MaterialColorsArgs = {
    order?: Maybe<Array<Maybe<ColorFilter_Order>>>;
};

/** Cursor connection for Material. */
export type MaterialCursorConnection = {
    __typename?: 'MaterialCursorConnection';
    edges?: Maybe<Array<Maybe<MaterialEdge>>>;
    pageInfo: MaterialPageInfo;
    totalCount: Scalars['Int'];
};

/** Edge of Material. */
export type MaterialEdge = {
    __typename?: 'MaterialEdge';
    node?: Maybe<Material>;
    cursor: Scalars['String'];
};

export type MaterialGroup = Node & {
    __typename?: 'MaterialGroup';
    id: Scalars['ID'];
    _id: Scalars['Int'];
    name: Scalars['String'];
    code: Scalars['String'];
    materials?: Maybe<MaterialCursorConnection>;
    parent?: Maybe<MaterialGroup>;
    children?: Maybe<Array<Maybe<MaterialGroup>>>;
    fieldRules?: Maybe<Array<Maybe<MaterialGroupFieldRule>>>;
    propertyTypeRules?: Maybe<Array<Maybe<MaterialGroupPropertyTypeRule>>>;
    sort: Scalars['Int'];
};

export type MaterialGroupMaterialsArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    isDeleted: Scalars['Boolean'];
    materialGroups?: Maybe<Scalars['Iterable']>;
    id?: Maybe<Scalars['Int']>;
    id_list?: Maybe<Array<Maybe<Scalars['Int']>>>;
    name?: Maybe<Scalars['String']>;
    category?: Maybe<Scalars['String']>;
    category_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    scopes?: Maybe<Scalars['String']>;
    scopes_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    locations?: Maybe<Scalars['String']>;
    locations_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    keyFigures?: Maybe<Scalars['String']>;
    keyFigures_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    properties?: Maybe<Scalars['String']>;
    properties_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    colors?: Maybe<Scalars['String']>;
    colors_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    active?: Maybe<Scalars['Boolean']>;
};

export type MaterialGroupChildrenArgs = {
    order?: Maybe<Array<Maybe<MaterialGroupFilter_Order>>>;
    exists?: Maybe<Array<Maybe<MaterialGroupFilter_Exists>>>;
};

export type MaterialGroupFieldRule = Node & {
    __typename?: 'MaterialGroupFieldRule';
    id: Scalars['ID'];
    _id: Scalars['Int'];
    materialGroup: MaterialGroup;
    field: Scalars['String'];
    rule: Scalars['String'];
};

export type MaterialGroupFilter_Exists = {
    parent?: Maybe<Scalars['Boolean']>;
};

export type MaterialGroupFilter_Order = {
    sort?: Maybe<Scalars['String']>;
};

export type MaterialGroupPropertyTypeRule = Node & {
    __typename?: 'MaterialGroupPropertyTypeRule';
    id: Scalars['ID'];
    _id: Scalars['Int'];
    materialGroup: MaterialGroup;
    propertyType: PropertyType;
    rule: Scalars['String'];
};

export type MaterialImage = Node & {
    __typename?: 'MaterialImage';
    id: Scalars['ID'];
    _id: Scalars['Int'];
    filePath?: Maybe<MaterialImageResource>;
    material?: Maybe<Material>;
    caption: Scalars['String'];
    type: Scalars['String'];
};

export type MaterialImageResource = {
    __typename?: 'MaterialImageResource';
    id: Scalars['ID'];
    original: Scalars['String'];
    small: Scalars['String'];
    large: Scalars['String'];
};

/** Information about the current page. */
export type MaterialPageInfo = {
    __typename?: 'MaterialPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    startCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
};

export type MaterialScope = Node & {
    __typename?: 'MaterialScope';
    id: Scalars['ID'];
    _id: Scalars['Int'];
    name: Scalars['String'];
    materials?: Maybe<MaterialCursorConnection>;
    sort: Scalars['Int'];
};

export type MaterialScopeMaterialsArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    isDeleted: Scalars['Boolean'];
    materialGroups?: Maybe<Scalars['Iterable']>;
    id?: Maybe<Scalars['Int']>;
    id_list?: Maybe<Array<Maybe<Scalars['Int']>>>;
    name?: Maybe<Scalars['String']>;
    category?: Maybe<Scalars['String']>;
    category_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    scopes?: Maybe<Scalars['String']>;
    scopes_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    locations?: Maybe<Scalars['String']>;
    locations_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    keyFigures?: Maybe<Scalars['String']>;
    keyFigures_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    properties?: Maybe<Scalars['String']>;
    properties_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    colors?: Maybe<Scalars['String']>;
    colors_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    active?: Maybe<Scalars['Boolean']>;
};

export type MaterialScopeFilter_Order = {
    sort?: Maybe<Scalars['String']>;
};

export type Mutation = {
    __typename?: 'Mutation';
    /** Deletes a Change. */
    deleteChange?: Maybe<DeleteChangePayload>;
    /** Updates a Change. */
    updateChange?: Maybe<UpdateChangePayload>;
    /** Creates a Change. */
    createChange?: Maybe<CreateChangePayload>;
    /** Creates a Color. */
    createColor?: Maybe<CreateColorPayload>;
    /** Updates a Color. */
    updateColor?: Maybe<UpdateColorPayload>;
    /** Deletes a Color. */
    deleteColor?: Maybe<DeleteColorPayload>;
    /** Creates a Group. */
    createGroup?: Maybe<CreateGroupPayload>;
    /** Updates a Group. */
    updateGroup?: Maybe<UpdateGroupPayload>;
    /** Deletes a Group. */
    deleteGroup?: Maybe<DeleteGroupPayload>;
    /** Creates a KeyFigure. */
    createKeyFigure?: Maybe<CreateKeyFigurePayload>;
    /** Updates a KeyFigure. */
    updateKeyFigure?: Maybe<UpdateKeyFigurePayload>;
    /** Deletes a KeyFigure. */
    deleteKeyFigure?: Maybe<DeleteKeyFigurePayload>;
    /** Creates a KeyFigureCategory. */
    createKeyFigureCategory?: Maybe<CreateKeyFigureCategoryPayload>;
    /** Updates a KeyFigureCategory. */
    updateKeyFigureCategory?: Maybe<UpdateKeyFigureCategoryPayload>;
    /** Deletes a KeyFigureCategory. */
    deleteKeyFigureCategory?: Maybe<DeleteKeyFigureCategoryPayload>;
    /** Creates a KeyFigureValue. */
    createKeyFigureValue?: Maybe<CreateKeyFigureValuePayload>;
    /** Updates a KeyFigureValue. */
    updateKeyFigureValue?: Maybe<UpdateKeyFigureValuePayload>;
    /** Deletes a KeyFigureValue. */
    deleteKeyFigureValue?: Maybe<DeleteKeyFigureValuePayload>;
    /** Creates a Location. */
    createLocation?: Maybe<CreateLocationPayload>;
    /** Updates a Location. */
    updateLocation?: Maybe<UpdateLocationPayload>;
    /** Deletes a Location. */
    deleteLocation?: Maybe<DeleteLocationPayload>;
    /** Creates a Material. */
    createMaterial?: Maybe<CreateMaterialPayload>;
    /** Updates a Material. */
    updateMaterial?: Maybe<UpdateMaterialPayload>;
    /** Deletes a Material. */
    deleteMaterial?: Maybe<DeleteMaterialPayload>;
    /** Mark Material for delete */
    markForDeletionMaterial?: Maybe<MarkForDeletionMaterialPayload>;
    /** Creates a MaterialGroup. */
    createMaterialGroup?: Maybe<CreateMaterialGroupPayload>;
    /** Updates a MaterialGroup. */
    updateMaterialGroup?: Maybe<UpdateMaterialGroupPayload>;
    /** Deletes a MaterialGroup. */
    deleteMaterialGroup?: Maybe<DeleteMaterialGroupPayload>;
    /** Creates a MaterialGroupFieldRule. */
    createMaterialGroupFieldRule?: Maybe<CreateMaterialGroupFieldRulePayload>;
    /** Updates a MaterialGroupFieldRule. */
    updateMaterialGroupFieldRule?: Maybe<UpdateMaterialGroupFieldRulePayload>;
    /** Deletes a MaterialGroupFieldRule. */
    deleteMaterialGroupFieldRule?: Maybe<DeleteMaterialGroupFieldRulePayload>;
    /** Creates a MaterialGroupPropertyTypeRule. */
    createMaterialGroupPropertyTypeRule?: Maybe<CreateMaterialGroupPropertyTypeRulePayload>;
    /** Updates a MaterialGroupPropertyTypeRule. */
    updateMaterialGroupPropertyTypeRule?: Maybe<UpdateMaterialGroupPropertyTypeRulePayload>;
    /** Deletes a MaterialGroupPropertyTypeRule. */
    deleteMaterialGroupPropertyTypeRule?: Maybe<DeleteMaterialGroupPropertyTypeRulePayload>;
    /** Creates a MaterialImage. */
    createMaterialImage?: Maybe<CreateMaterialImagePayload>;
    /** Updates a MaterialImage. */
    updateMaterialImage?: Maybe<UpdateMaterialImagePayload>;
    /** Deletes a MaterialImage. */
    deleteMaterialImage?: Maybe<DeleteMaterialImagePayload>;
    /** Uploads a MaterialImage. */
    uploadMaterialImage?: Maybe<UploadMaterialImagePayload>;
    /** Creates a MaterialScope. */
    createMaterialScope?: Maybe<CreateMaterialScopePayload>;
    /** Updates a MaterialScope. */
    updateMaterialScope?: Maybe<UpdateMaterialScopePayload>;
    /** Deletes a MaterialScope. */
    deleteMaterialScope?: Maybe<DeleteMaterialScopePayload>;
    /** Creates a Property. */
    createProperty?: Maybe<CreatePropertyPayload>;
    /** Updates a Property. */
    updateProperty?: Maybe<UpdatePropertyPayload>;
    /** Deletes a Property. */
    deleteProperty?: Maybe<DeletePropertyPayload>;
    /** Creates a PropertyType. */
    createPropertyType?: Maybe<CreatePropertyTypePayload>;
    /** Updates a PropertyType. */
    updatePropertyType?: Maybe<UpdatePropertyTypePayload>;
    /** Deletes a PropertyType. */
    deletePropertyType?: Maybe<DeletePropertyTypePayload>;
    /** Creates a Revision. */
    createRevision?: Maybe<CreateRevisionPayload>;
    /** Updates a Revision. */
    updateRevision?: Maybe<UpdateRevisionPayload>;
    /** Deletes a Revision. */
    deleteRevision?: Maybe<DeleteRevisionPayload>;
    /** Reviews a Revision. */
    reviewRevision?: Maybe<ReviewRevisionPayload>;
    /** Updates a User. */
    updateUser?: Maybe<UpdateUserPayload>;
    /** Creates a User. */
    createUser?: Maybe<CreateUserPayload>;
    /** Removes a User. */
    removeUser?: Maybe<RemoveUserPayload>;
    /** ChangePasswords a User. */
    changePasswordUser?: Maybe<ChangePasswordUserPayload>;
    /** AdminChangePasswords a User. */
    adminChangePasswordUser?: Maybe<AdminChangePasswordUserPayload>;
    /** Deactivation user */
    deactivateUser?: Maybe<DeactivateUserPayload>;
    /** Activation user */
    activateUser?: Maybe<ActivateUserPayload>;
    releaseLock?: Maybe<ReleaseLockResult>;
    refreshLock?: Maybe<RefreshxLockResult>;
    createMultipleChanges: Array<Change>;
    createMultipleGroups: Array<Group>;
    createMultipleKeyFigureValues: Array<KeyFigureValue>;
    updateMultipleKeyFigureValues: Array<KeyFigureValue>;
    deleteMultipleKeyFigureValues?: Maybe<Scalars['Boolean']>;
    createMultipleMaterials: Array<Material>;
    updateMultipleMaterialGroups: Array<MaterialGroup>;
    updateMultipleMaterialImages: Array<MaterialImage>;
    createMultipleRevisions: Array<Revision>;
    createMultipleUsers: Array<User>;
};

export type MutationDeleteChangeArgs = {
    input: DeleteChangeInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationUpdateChangeArgs = {
    input: UpdateChangeInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationCreateChangeArgs = {
    input: CreateChangeInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationCreateColorArgs = {
    input: CreateColorInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationUpdateColorArgs = {
    input: UpdateColorInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationDeleteColorArgs = {
    input: DeleteColorInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationCreateGroupArgs = {
    input: CreateGroupInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationUpdateGroupArgs = {
    input: UpdateGroupInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationDeleteGroupArgs = {
    input: DeleteGroupInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationCreateKeyFigureArgs = {
    input: CreateKeyFigureInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationUpdateKeyFigureArgs = {
    input: UpdateKeyFigureInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationDeleteKeyFigureArgs = {
    input: DeleteKeyFigureInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationCreateKeyFigureCategoryArgs = {
    input: CreateKeyFigureCategoryInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationUpdateKeyFigureCategoryArgs = {
    input: UpdateKeyFigureCategoryInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationDeleteKeyFigureCategoryArgs = {
    input: DeleteKeyFigureCategoryInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationCreateKeyFigureValueArgs = {
    input: CreateKeyFigureValueInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationUpdateKeyFigureValueArgs = {
    input: UpdateKeyFigureValueInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationDeleteKeyFigureValueArgs = {
    input: DeleteKeyFigureValueInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationCreateLocationArgs = {
    input: CreateLocationInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationUpdateLocationArgs = {
    input: UpdateLocationInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationDeleteLocationArgs = {
    input: DeleteLocationInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationCreateMaterialArgs = {
    input: CreateMaterialInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationUpdateMaterialArgs = {
    input: UpdateMaterialInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationDeleteMaterialArgs = {
    input: DeleteMaterialInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationMarkForDeletionMaterialArgs = {
    input: MarkForDeletionMaterialInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationCreateMaterialGroupArgs = {
    input: CreateMaterialGroupInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationUpdateMaterialGroupArgs = {
    input: UpdateMaterialGroupInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationDeleteMaterialGroupArgs = {
    input: DeleteMaterialGroupInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationCreateMaterialGroupFieldRuleArgs = {
    input: CreateMaterialGroupFieldRuleInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationUpdateMaterialGroupFieldRuleArgs = {
    input: UpdateMaterialGroupFieldRuleInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationDeleteMaterialGroupFieldRuleArgs = {
    input: DeleteMaterialGroupFieldRuleInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationCreateMaterialGroupPropertyTypeRuleArgs = {
    input: CreateMaterialGroupPropertyTypeRuleInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationUpdateMaterialGroupPropertyTypeRuleArgs = {
    input: UpdateMaterialGroupPropertyTypeRuleInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationDeleteMaterialGroupPropertyTypeRuleArgs = {
    input: DeleteMaterialGroupPropertyTypeRuleInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationCreateMaterialImageArgs = {
    input: CreateMaterialImageInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationUpdateMaterialImageArgs = {
    input: UpdateMaterialImageInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationDeleteMaterialImageArgs = {
    input: DeleteMaterialImageInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationUploadMaterialImageArgs = {
    input: UploadMaterialImageInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationCreateMaterialScopeArgs = {
    input: CreateMaterialScopeInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationUpdateMaterialScopeArgs = {
    input: UpdateMaterialScopeInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationDeleteMaterialScopeArgs = {
    input: DeleteMaterialScopeInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationCreatePropertyArgs = {
    input: CreatePropertyInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationUpdatePropertyArgs = {
    input: UpdatePropertyInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationDeletePropertyArgs = {
    input: DeletePropertyInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationCreatePropertyTypeArgs = {
    input: CreatePropertyTypeInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationUpdatePropertyTypeArgs = {
    input: UpdatePropertyTypeInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationDeletePropertyTypeArgs = {
    input: DeletePropertyTypeInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationCreateRevisionArgs = {
    input: CreateRevisionInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationUpdateRevisionArgs = {
    input: UpdateRevisionInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationDeleteRevisionArgs = {
    input: DeleteRevisionInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationReviewRevisionArgs = {
    input: ReviewRevisionInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationUpdateUserArgs = {
    input: UpdateUserInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationCreateUserArgs = {
    input: CreateUserInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationRemoveUserArgs = {
    input: RemoveUserInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationChangePasswordUserArgs = {
    input: ChangePasswordUserInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationAdminChangePasswordUserArgs = {
    input: AdminChangePasswordUserInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationDeactivateUserArgs = {
    input: DeactivateUserInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationActivateUserArgs = {
    input: ActivateUserInput;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationReleaseLockArgs = {
    id: Scalars['ID'];
};

export type MutationRefreshLockArgs = {
    id: Scalars['ID'];
};

export type MutationCreateMultipleChangesArgs = {
    changes: Array<CreateChangeWithTempIdInput>;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationCreateMultipleGroupsArgs = {
    groups: Array<CreateGroupWithTempIdInput>;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationCreateMultipleKeyFigureValuesArgs = {
    keyFigureValues: Array<CreateKeyFigureValueWithTempIdInput>;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationUpdateMultipleKeyFigureValuesArgs = {
    keyFigureValues: Array<UpdateKeyFigureValueInput>;
    lock?: Maybe<Scalars['ID']>;
    implicitRelease?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteMultipleKeyFigureValuesArgs = {
    ids: Array<Scalars['ID']>;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationCreateMultipleMaterialsArgs = {
    materials: Array<CreateMaterialWithTempIdInput>;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationUpdateMultipleMaterialGroupsArgs = {
    materialGroups: Array<UpdateMaterialGroupInput>;
    lock?: Maybe<Scalars['ID']>;
    implicitRelease?: Maybe<Scalars['Boolean']>;
};

export type MutationUpdateMultipleMaterialImagesArgs = {
    materialImages: Array<UpdateMaterialImageInput>;
    lock?: Maybe<Scalars['ID']>;
    implicitRelease?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateMultipleRevisionsArgs = {
    revisions: Array<CreateRevisionWithTempIdInput>;
    lock?: Maybe<Scalars['ID']>;
};

export type MutationCreateMultipleUsersArgs = {
    users: Array<CreateUserWithTempIdInput>;
    lock?: Maybe<Scalars['ID']>;
};

/** A node, according to the Relay specification. */
export type Node = {
    /** The id of this node. */
    id: Scalars['ID'];
};

export type Property = Node & {
    __typename?: 'Property';
    id: Scalars['ID'];
    _id: Scalars['Int'];
    name: Scalars['String'];
    materials?: Maybe<MaterialCursorConnection>;
    type: PropertyType;
    image?: Maybe<Scalars['String']>;
    color?: Maybe<Scalars['String']>;
    sort: Scalars['Int'];
};

export type PropertyMaterialsArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    isDeleted: Scalars['Boolean'];
    materialGroups?: Maybe<Scalars['Iterable']>;
    id?: Maybe<Scalars['Int']>;
    id_list?: Maybe<Array<Maybe<Scalars['Int']>>>;
    name?: Maybe<Scalars['String']>;
    category?: Maybe<Scalars['String']>;
    category_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    scopes?: Maybe<Scalars['String']>;
    scopes_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    locations?: Maybe<Scalars['String']>;
    locations_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    keyFigures?: Maybe<Scalars['String']>;
    keyFigures_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    properties?: Maybe<Scalars['String']>;
    properties_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    colors?: Maybe<Scalars['String']>;
    colors_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    active?: Maybe<Scalars['Boolean']>;
};

export type PropertyFilter_Order = {
    sort?: Maybe<Scalars['String']>;
};

export type PropertyType = Node & {
    __typename?: 'PropertyType';
    id: Scalars['ID'];
    _id: Scalars['Int'];
    name: Scalars['String'];
    category: Scalars['String'];
    properties?: Maybe<Array<Maybe<Property>>>;
    allowMultiple: Scalars['Boolean'];
    materialGroupRules?: Maybe<Array<Maybe<MaterialGroupPropertyTypeRule>>>;
    defaultFormRule?: Maybe<Scalars['String']>;
    sort: Scalars['Int'];
};

export type PropertyTypePropertiesArgs = {
    order?: Maybe<Array<Maybe<PropertyFilter_Order>>>;
};

export type PropertyTypeFilter_Order = {
    sort?: Maybe<Scalars['String']>;
};

export type Query = {
    __typename?: 'Query';
    node?: Maybe<Node>;
    change?: Maybe<Change>;
    changes?: Maybe<Array<Maybe<Change>>>;
    colors?: Maybe<Array<Maybe<Color>>>;
    color?: Maybe<Color>;
    /** Get currently logged in user */
    meGroup?: Maybe<Group>;
    groups?: Maybe<GroupCursorConnection>;
    group?: Maybe<Group>;
    keyFigures?: Maybe<Array<Maybe<KeyFigure>>>;
    keyFigure?: Maybe<KeyFigure>;
    keyFigureCategories?: Maybe<Array<Maybe<KeyFigureCategory>>>;
    keyFigureCategory?: Maybe<KeyFigureCategory>;
    keyFigureValues?: Maybe<Array<Maybe<KeyFigureValue>>>;
    keyFigureValue?: Maybe<KeyFigureValue>;
    locations?: Maybe<Array<Maybe<Location>>>;
    location?: Maybe<Location>;
    materials?: Maybe<MaterialCursorConnection>;
    material?: Maybe<Material>;
    materialGroups?: Maybe<Array<Maybe<MaterialGroup>>>;
    materialGroup?: Maybe<MaterialGroup>;
    materialGroupFieldRules?: Maybe<Array<Maybe<MaterialGroupFieldRule>>>;
    materialGroupFieldRule?: Maybe<MaterialGroupFieldRule>;
    materialGroupPropertyTypeRules?: Maybe<Array<Maybe<MaterialGroupPropertyTypeRule>>>;
    materialGroupPropertyTypeRule?: Maybe<MaterialGroupPropertyTypeRule>;
    materialImage?: Maybe<MaterialImage>;
    materialScopes?: Maybe<Array<Maybe<MaterialScope>>>;
    materialScope?: Maybe<MaterialScope>;
    properties?: Maybe<Array<Maybe<Property>>>;
    property?: Maybe<Property>;
    propertyTypes?: Maybe<Array<Maybe<PropertyType>>>;
    propertyType?: Maybe<PropertyType>;
    revision?: Maybe<Revision>;
    revisions?: Maybe<RevisionCursorConnection>;
    /** Get currently logged in user */
    meUser?: Maybe<User>;
    user?: Maybe<User>;
    users?: Maybe<UserCursorConnection>;
};

export type QueryNodeArgs = {
    id: Scalars['ID'];
};

export type QueryChangeArgs = {
    id: Scalars['ID'];
};

export type QueryColorsArgs = {
    order?: Maybe<Array<Maybe<ColorFilter_Order>>>;
};

export type QueryColorArgs = {
    id: Scalars['ID'];
};

export type QueryGroupsArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
};

export type QueryGroupArgs = {
    id: Scalars['ID'];
};

export type QueryKeyFiguresArgs = {
    order?: Maybe<Array<Maybe<KeyFigureFilter_Order>>>;
};

export type QueryKeyFigureArgs = {
    id: Scalars['ID'];
};

export type QueryKeyFigureCategoriesArgs = {
    order?: Maybe<Array<Maybe<KeyFigureCategoryFilter_Order>>>;
};

export type QueryKeyFigureCategoryArgs = {
    id: Scalars['ID'];
};

export type QueryKeyFigureValuesArgs = {
    order?: Maybe<Array<Maybe<KeyFigureValueFilter_Order>>>;
};

export type QueryKeyFigureValueArgs = {
    id: Scalars['ID'];
};

export type QueryLocationsArgs = {
    order?: Maybe<Array<Maybe<LocationFilter_Order>>>;
    exists?: Maybe<Array<Maybe<LocationFilter_Exists>>>;
};

export type QueryLocationArgs = {
    id: Scalars['ID'];
};

export type QueryMaterialsArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    isDeleted: Scalars['Boolean'];
    materialGroups?: Maybe<Scalars['Iterable']>;
    id?: Maybe<Scalars['Int']>;
    id_list?: Maybe<Array<Maybe<Scalars['Int']>>>;
    name?: Maybe<Scalars['String']>;
    category?: Maybe<Scalars['String']>;
    category_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    scopes?: Maybe<Scalars['String']>;
    scopes_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    locations?: Maybe<Scalars['String']>;
    locations_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    keyFigures?: Maybe<Scalars['String']>;
    keyFigures_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    properties?: Maybe<Scalars['String']>;
    properties_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    colors?: Maybe<Scalars['String']>;
    colors_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    active?: Maybe<Scalars['Boolean']>;
};

export type QueryMaterialArgs = {
    id: Scalars['ID'];
};

export type QueryMaterialGroupsArgs = {
    order?: Maybe<Array<Maybe<MaterialGroupFilter_Order>>>;
    exists?: Maybe<Array<Maybe<MaterialGroupFilter_Exists>>>;
};

export type QueryMaterialGroupArgs = {
    id: Scalars['ID'];
};

export type QueryMaterialGroupFieldRuleArgs = {
    id: Scalars['ID'];
};

export type QueryMaterialGroupPropertyTypeRuleArgs = {
    id: Scalars['ID'];
};

export type QueryMaterialImageArgs = {
    id: Scalars['ID'];
};

export type QueryMaterialScopesArgs = {
    order?: Maybe<Array<Maybe<MaterialScopeFilter_Order>>>;
};

export type QueryMaterialScopeArgs = {
    id: Scalars['ID'];
};

export type QueryPropertiesArgs = {
    order?: Maybe<Array<Maybe<PropertyFilter_Order>>>;
};

export type QueryPropertyArgs = {
    id: Scalars['ID'];
};

export type QueryPropertyTypesArgs = {
    order?: Maybe<Array<Maybe<PropertyTypeFilter_Order>>>;
};

export type QueryPropertyTypeArgs = {
    id: Scalars['ID'];
};

export type QueryRevisionArgs = {
    id: Scalars['ID'];
};

export type QueryRevisionsArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    exists?: Maybe<Array<Maybe<RevisionFilter_Exists>>>;
};

export type QueryUserArgs = {
    id: Scalars['ID'];
};

export type QueryUsersArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    email_list?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Revision = Node & {
    __typename?: 'Revision';
    id: Scalars['ID'];
    _id: Scalars['Int'];
    changes?: Maybe<Array<Maybe<Change>>>;
    material: Material;
    author: User;
    pending: Scalars['Boolean'];
    description: Scalars['String'];
    applied?: Maybe<Scalars['String']>;
    discarded?: Maybe<Scalars['String']>;
    reviewer?: Maybe<User>;
    createdAt: Scalars['String'];
};

/** Cursor connection for Revision. */
export type RevisionCursorConnection = {
    __typename?: 'RevisionCursorConnection';
    edges?: Maybe<Array<Maybe<RevisionEdge>>>;
    pageInfo: RevisionPageInfo;
    totalCount: Scalars['Int'];
};

/** Edge of Revision. */
export type RevisionEdge = {
    __typename?: 'RevisionEdge';
    node?: Maybe<Revision>;
    cursor: Scalars['String'];
};

export type RevisionFilter_Exists = {
    applied?: Maybe<Scalars['Boolean']>;
    discarded?: Maybe<Scalars['Boolean']>;
};

/** Information about the current page. */
export type RevisionPageInfo = {
    __typename?: 'RevisionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    startCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
};

export type User = Node & {
    __typename?: 'User';
    id: Scalars['ID'];
    _id: Scalars['Int'];
    email: Scalars['String'];
    roles: Scalars['Iterable'];
    groups?: Maybe<GroupCursorConnection>;
    /** Organisation: KIT, BUW, FUH usw. */
    organization?: Maybe<Scalars['String']>;
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    revisions?: Maybe<RevisionCursorConnection>;
    reviewedRevisions?: Maybe<RevisionCursorConnection>;
    createdMaterials?: Maybe<MaterialCursorConnection>;
    lastUpdatedMaterials?: Maybe<MaterialCursorConnection>;
    isDeactivated: Scalars['Boolean'];
    /** A visual identifier that represents this user. */
    username: Scalars['String'];
};

export type UserGroupsArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
};

export type UserRevisionsArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    exists?: Maybe<Array<Maybe<RevisionFilter_Exists>>>;
};

export type UserReviewedRevisionsArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    exists?: Maybe<Array<Maybe<RevisionFilter_Exists>>>;
};

export type UserCreatedMaterialsArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    isDeleted: Scalars['Boolean'];
    materialGroups?: Maybe<Scalars['Iterable']>;
    id?: Maybe<Scalars['Int']>;
    id_list?: Maybe<Array<Maybe<Scalars['Int']>>>;
    name?: Maybe<Scalars['String']>;
    category?: Maybe<Scalars['String']>;
    category_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    scopes?: Maybe<Scalars['String']>;
    scopes_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    locations?: Maybe<Scalars['String']>;
    locations_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    keyFigures?: Maybe<Scalars['String']>;
    keyFigures_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    properties?: Maybe<Scalars['String']>;
    properties_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    colors?: Maybe<Scalars['String']>;
    colors_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    active?: Maybe<Scalars['Boolean']>;
};

export type UserLastUpdatedMaterialsArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    isDeleted: Scalars['Boolean'];
    materialGroups?: Maybe<Scalars['Iterable']>;
    id?: Maybe<Scalars['Int']>;
    id_list?: Maybe<Array<Maybe<Scalars['Int']>>>;
    name?: Maybe<Scalars['String']>;
    category?: Maybe<Scalars['String']>;
    category_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    scopes?: Maybe<Scalars['String']>;
    scopes_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    locations?: Maybe<Scalars['String']>;
    locations_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    keyFigures?: Maybe<Scalars['String']>;
    keyFigures_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    properties?: Maybe<Scalars['String']>;
    properties_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    colors?: Maybe<Scalars['String']>;
    colors_list?: Maybe<Array<Maybe<Scalars['String']>>>;
    active?: Maybe<Scalars['Boolean']>;
};

/** Cursor connection for User. */
export type UserCursorConnection = {
    __typename?: 'UserCursorConnection';
    edges?: Maybe<Array<Maybe<UserEdge>>>;
    pageInfo: UserPageInfo;
    totalCount: Scalars['Int'];
};

/** Edge of User. */
export type UserEdge = {
    __typename?: 'UserEdge';
    node?: Maybe<User>;
    cursor: Scalars['String'];
};

/** Information about the current page. */
export type UserPageInfo = {
    __typename?: 'UserPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    startCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
};

export type ActivateUserInput = {
    /** The id of the user which will be activated */
    id: Scalars['ID'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type ActivateUserPayload = {
    __typename?: 'activateUserPayload';
    user?: Maybe<User>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type AdminChangePasswordUserInput = {
    newPassword: Scalars['String'];
    /** Optional id for the user where the password change will occur */
    id: Scalars['ID'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type AdminChangePasswordUserPayload = {
    __typename?: 'adminChangePasswordUserPayload';
    user?: Maybe<User>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type ChangePasswordUserInput = {
    currentPassword: Scalars['String'];
    newPassword: Scalars['String'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type ChangePasswordUserPayload = {
    __typename?: 'changePasswordUserPayload';
    user?: Maybe<User>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateChangeInput = {
    revision: Scalars['String'];
    field: Scalars['String'];
    value?: Maybe<Scalars['String']>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateChangePayload = {
    __typename?: 'createChangePayload';
    change?: Maybe<Change>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateChangeWithTempIdInput = {
    revision: Scalars['String'];
    field: Scalars['String'];
    value?: Maybe<Scalars['String']>;
    clientMutationId?: Maybe<Scalars['String']>;
    /** A temporary ID that can be used in subsequent mutations (only in create / update multiple mutations) */
    tmpId?: Maybe<Scalars['String']>;
};

export type CreateColorInput = {
    name: Scalars['String'];
    color?: Maybe<Scalars['String']>;
    materials?: Maybe<Array<Maybe<Scalars['String']>>>;
    sort: Scalars['Int'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateColorPayload = {
    __typename?: 'createColorPayload';
    color?: Maybe<Color>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateGroupInput = {
    name: Scalars['String'];
    description: Scalars['String'];
    externalLink: Scalars['String'];
    users?: Maybe<Array<Maybe<Scalars['String']>>>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateGroupPayload = {
    __typename?: 'createGroupPayload';
    group?: Maybe<Group>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateGroupWithTempIdInput = {
    name: Scalars['String'];
    description: Scalars['String'];
    externalLink: Scalars['String'];
    users?: Maybe<Array<Maybe<Scalars['String']>>>;
    clientMutationId?: Maybe<Scalars['String']>;
    /** A temporary ID that can be used in subsequent mutations (only in create / update multiple mutations) */
    tmpId?: Maybe<Scalars['String']>;
};

export type CreateKeyFigureCategoryInput = {
    name: Scalars['String'];
    keyFigures?: Maybe<Array<Maybe<Scalars['String']>>>;
    formArea: Scalars['String'];
    additionalInformation?: Maybe<Scalars['String']>;
    sort: Scalars['Int'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateKeyFigureCategoryPayload = {
    __typename?: 'createKeyFigureCategoryPayload';
    keyFigureCategory?: Maybe<KeyFigureCategory>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateKeyFigureInput = {
    name: Scalars['String'];
    symbol: Scalars['String'];
    unit: Scalars['String'];
    category: Scalars['String'];
    type: Scalars['String'];
    placeholderText?: Maybe<Scalars['String']>;
    sort: Scalars['Int'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateKeyFigurePayload = {
    __typename?: 'createKeyFigurePayload';
    keyFigure?: Maybe<KeyFigure>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateKeyFigureValueInput = {
    lowerValue: Scalars['Float'];
    upperValue?: Maybe<Scalars['Float']>;
    material: Scalars['String'];
    keyFigure: Scalars['String'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateKeyFigureValuePayload = {
    __typename?: 'createKeyFigureValuePayload';
    keyFigureValue?: Maybe<KeyFigureValue>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateKeyFigureValueWithTempIdInput = {
    lowerValue: Scalars['Float'];
    upperValue?: Maybe<Scalars['Float']>;
    material: Scalars['String'];
    keyFigure: Scalars['String'];
    clientMutationId?: Maybe<Scalars['String']>;
    /** A temporary ID that can be used in subsequent mutations (only in create / update multiple mutations) */
    tmpId?: Maybe<Scalars['String']>;
};

export type CreateLocationInput = {
    name: Scalars['String'];
    parent?: Maybe<Scalars['String']>;
    children?: Maybe<Array<Maybe<Scalars['String']>>>;
    materials?: Maybe<Array<Maybe<Scalars['String']>>>;
    sort: Scalars['Int'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateLocationPayload = {
    __typename?: 'createLocationPayload';
    location?: Maybe<Location>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateMaterialGroupFieldRuleInput = {
    materialGroup: Scalars['String'];
    field: Scalars['String'];
    rule: Scalars['String'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateMaterialGroupFieldRulePayload = {
    __typename?: 'createMaterialGroupFieldRulePayload';
    materialGroupFieldRule?: Maybe<MaterialGroupFieldRule>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateMaterialGroupInput = {
    name: Scalars['String'];
    code: Scalars['String'];
    materials?: Maybe<Array<Maybe<Scalars['String']>>>;
    parent?: Maybe<Scalars['String']>;
    children?: Maybe<Array<Maybe<Scalars['String']>>>;
    fieldRules?: Maybe<Array<Maybe<Scalars['String']>>>;
    propertyTypeRules?: Maybe<Array<Maybe<Scalars['String']>>>;
    sort: Scalars['Int'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateMaterialGroupPayload = {
    __typename?: 'createMaterialGroupPayload';
    materialGroup?: Maybe<MaterialGroup>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateMaterialGroupPropertyTypeRuleInput = {
    materialGroup: Scalars['String'];
    propertyType: Scalars['String'];
    rule: Scalars['String'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateMaterialGroupPropertyTypeRulePayload = {
    __typename?: 'createMaterialGroupPropertyTypeRulePayload';
    materialGroupPropertyTypeRule?: Maybe<MaterialGroupPropertyTypeRule>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateMaterialImageInput = {
    material?: Maybe<Scalars['String']>;
    caption: Scalars['String'];
    type: Scalars['String'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateMaterialImagePayload = {
    __typename?: 'createMaterialImagePayload';
    materialImage?: Maybe<MaterialImage>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateMaterialInput = {
    active: Scalars['Boolean'];
    /** Materialname */
    name: Scalars['String'];
    /** Wissenschaftliche Bezeichnungen */
    scientificName: Scalars['String'];
    /** Weitere Bezeichnungen */
    additionalNames: Scalars['String'];
    /** Materialuntergruppe, über parent property Zugriff auf Materialgruppe und Materialklasse */
    category: Scalars['String'];
    /** Anwendungsbereich */
    scopes?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Anwendung, Ergänzung */
    additionalScopes: Scalars['String'];
    /** Beschreibung */
    description: Scalars['String'];
    /** Herkunft, Entstehung, Herstellung, geografisch */
    locations?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Wachstum / Kultivierung */
    cultivation: Scalars['String'];
    /** Entstehung */
    origin: Scalars['String'];
    /** Gewinnung */
    extraction: Scalars['String'];
    /** Herstellung */
    production: Scalars['String'];
    /** Weitere Verarbeitung */
    specialProcessing: Scalars['String'];
    /** Revisionen: Nach Änderungen an Materialien werden neue Daten nicht sofort veröffentlicht sondern als Revision abgelegt. Diese können mit spezillen Berechtigungen freigegeben werden. */
    revisions?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Kennzahlen (Mechanik / Thermodynamik) */
    keyFigures?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Geruch */
    smell: Scalars['String'];
    /** Eigenschaften aus verschiedenen Bereichen (Optik, Struktur, Textur, Oberfläche / Haptik, Format / Dimension, Beständigkeit, ...) */
    properties?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Farbbereich */
    colors?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Farbbesonderheiten */
    additionalColorInformation: Scalars['String'];
    /** Dauerhaftigkeit */
    durability: Scalars['String'];
    /** Brandverhalten, Ergänzung */
    fireBehaviorAdditions: Scalars['String'];
    /** Lebensdauer nach BKI */
    durabilityBKI: Scalars['String'];
    /** Nutzungsdauer Jahre nach BNB */
    durabilityBNB: Scalars['String'];
    /** Risiko für die lokale Umwelt */
    localEnvironmentRisk: Scalars['String'];
    /** Ökobilanz, Bezug */
    ecologicalBalanceReference: Scalars['String'];
    /** Kreislauffähigkeit */
    recyclability: Scalars['String'];
    /** Anmerkungen zur Ökobilanz */
    ecologyAdditionalInformation: Scalars['String'];
    images?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Verbindungstechniken, Ergänzung */
    specialCoupling: Scalars['String'];
    /** Mechanik */
    mechanic: Scalars['String'];
    /** Thermodynamik */
    thermodynamic: Scalars['String'];
    /** Beständigkeit */
    resistance: Scalars['String'];
    /** Gebrauchstechnik */
    usageTechnology: Scalars['String'];
    /** Akustik */
    acoustic: Scalars['String'];
    /** Sponsor BUW */
    sponsorBUW: Scalars['String'];
    /** Sponsor KIT */
    sponsorKIT: Scalars['String'];
    /** Sponsor MSA */
    sponsorMSA: Scalars['String'];
    /** Signatur BUW */
    signatureBUW: Scalars['String'];
    /** Signatur KIT */
    signatureKIT: Scalars['String'];
    /** Signatur MSA */
    signatureMSA: Scalars['String'];
    /** Copyright Bilddateien */
    copyrightImages: Scalars['String'];
    /** Copyright der Grafiken */
    copyrightGraphics: Scalars['String'];
    sort: Scalars['Int'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateMaterialPayload = {
    __typename?: 'createMaterialPayload';
    material?: Maybe<Material>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateMaterialScopeInput = {
    name: Scalars['String'];
    materials?: Maybe<Array<Maybe<Scalars['String']>>>;
    sort: Scalars['Int'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateMaterialScopePayload = {
    __typename?: 'createMaterialScopePayload';
    materialScope?: Maybe<MaterialScope>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateMaterialWithTempIdInput = {
    active: Scalars['Boolean'];
    /** Materialname */
    name: Scalars['String'];
    /** Wissenschaftliche Bezeichnungen */
    scientificName: Scalars['String'];
    /** Weitere Bezeichnungen */
    additionalNames: Scalars['String'];
    /** Materialuntergruppe, über parent property Zugriff auf Materialgruppe und Materialklasse */
    category: Scalars['String'];
    /** Anwendungsbereich */
    scopes?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Anwendung, Ergänzung */
    additionalScopes: Scalars['String'];
    /** Beschreibung */
    description: Scalars['String'];
    /** Herkunft, Entstehung, Herstellung, geografisch */
    locations?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Wachstum / Kultivierung */
    cultivation: Scalars['String'];
    /** Entstehung */
    origin: Scalars['String'];
    /** Gewinnung */
    extraction: Scalars['String'];
    /** Herstellung */
    production: Scalars['String'];
    /** Weitere Verarbeitung */
    specialProcessing: Scalars['String'];
    /** Revisionen: Nach Änderungen an Materialien werden neue Daten nicht sofort veröffentlicht sondern als Revision abgelegt. Diese können mit spezillen Berechtigungen freigegeben werden. */
    revisions?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Kennzahlen (Mechanik / Thermodynamik) */
    keyFigures?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Geruch */
    smell: Scalars['String'];
    /** Eigenschaften aus verschiedenen Bereichen (Optik, Struktur, Textur, Oberfläche / Haptik, Format / Dimension, Beständigkeit, ...) */
    properties?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Farbbereich */
    colors?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Farbbesonderheiten */
    additionalColorInformation: Scalars['String'];
    /** Dauerhaftigkeit */
    durability: Scalars['String'];
    /** Brandverhalten, Ergänzung */
    fireBehaviorAdditions: Scalars['String'];
    /** Lebensdauer nach BKI */
    durabilityBKI: Scalars['String'];
    /** Nutzungsdauer Jahre nach BNB */
    durabilityBNB: Scalars['String'];
    /** Risiko für die lokale Umwelt */
    localEnvironmentRisk: Scalars['String'];
    /** Ökobilanz, Bezug */
    ecologicalBalanceReference: Scalars['String'];
    /** Kreislauffähigkeit */
    recyclability: Scalars['String'];
    /** Anmerkungen zur Ökobilanz */
    ecologyAdditionalInformation: Scalars['String'];
    images?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Verbindungstechniken, Ergänzung */
    specialCoupling: Scalars['String'];
    /** Mechanik */
    mechanic: Scalars['String'];
    /** Thermodynamik */
    thermodynamic: Scalars['String'];
    /** Beständigkeit */
    resistance: Scalars['String'];
    /** Gebrauchstechnik */
    usageTechnology: Scalars['String'];
    /** Akustik */
    acoustic: Scalars['String'];
    /** Sponsor BUW */
    sponsorBUW: Scalars['String'];
    /** Sponsor KIT */
    sponsorKIT: Scalars['String'];
    /** Sponsor MSA */
    sponsorMSA: Scalars['String'];
    /** Signatur BUW */
    signatureBUW: Scalars['String'];
    /** Signatur KIT */
    signatureKIT: Scalars['String'];
    /** Signatur MSA */
    signatureMSA: Scalars['String'];
    /** Copyright Bilddateien */
    copyrightImages: Scalars['String'];
    /** Copyright der Grafiken */
    copyrightGraphics: Scalars['String'];
    sort: Scalars['Int'];
    clientMutationId?: Maybe<Scalars['String']>;
    /** A temporary ID that can be used in subsequent mutations (only in create / update multiple mutations) */
    tmpId?: Maybe<Scalars['String']>;
};

export type CreatePropertyInput = {
    name: Scalars['String'];
    materials?: Maybe<Array<Maybe<Scalars['String']>>>;
    type: Scalars['String'];
    image?: Maybe<Scalars['String']>;
    color?: Maybe<Scalars['String']>;
    sort: Scalars['Int'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePropertyPayload = {
    __typename?: 'createPropertyPayload';
    property?: Maybe<Property>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePropertyTypeInput = {
    name: Scalars['String'];
    category: Scalars['String'];
    properties?: Maybe<Array<Maybe<Scalars['String']>>>;
    allowMultiple: Scalars['Boolean'];
    materialGroupRules?: Maybe<Array<Maybe<Scalars['String']>>>;
    defaultFormRule?: Maybe<Scalars['String']>;
    sort: Scalars['Int'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePropertyTypePayload = {
    __typename?: 'createPropertyTypePayload';
    propertyType?: Maybe<PropertyType>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateRevisionInput = {
    changes?: Maybe<Array<Maybe<Scalars['String']>>>;
    material: Scalars['String'];
    description: Scalars['String'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateRevisionPayload = {
    __typename?: 'createRevisionPayload';
    revision?: Maybe<Revision>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateRevisionWithTempIdInput = {
    changes?: Maybe<Array<Maybe<Scalars['String']>>>;
    material: Scalars['String'];
    description: Scalars['String'];
    clientMutationId?: Maybe<Scalars['String']>;
    /** A temporary ID that can be used in subsequent mutations (only in create / update multiple mutations) */
    tmpId?: Maybe<Scalars['String']>;
};

export type CreateUserInput = {
    email: Scalars['String'];
    roles: Scalars['Iterable'];
    groups?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Organisation: KIT, BUW, FUH usw. */
    organization?: Maybe<Scalars['String']>;
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    revisions?: Maybe<Array<Maybe<Scalars['String']>>>;
    reviewedRevisions?: Maybe<Array<Maybe<Scalars['String']>>>;
    createdMaterials?: Maybe<Array<Maybe<Scalars['String']>>>;
    lastUpdatedMaterials?: Maybe<Array<Maybe<Scalars['String']>>>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateUserPayload = {
    __typename?: 'createUserPayload';
    user?: Maybe<User>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateUserWithTempIdInput = {
    email: Scalars['String'];
    roles: Scalars['Iterable'];
    groups?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Organisation: KIT, BUW, FUH usw. */
    organization?: Maybe<Scalars['String']>;
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    revisions?: Maybe<Array<Maybe<Scalars['String']>>>;
    reviewedRevisions?: Maybe<Array<Maybe<Scalars['String']>>>;
    createdMaterials?: Maybe<Array<Maybe<Scalars['String']>>>;
    lastUpdatedMaterials?: Maybe<Array<Maybe<Scalars['String']>>>;
    clientMutationId?: Maybe<Scalars['String']>;
    /** A temporary ID that can be used in subsequent mutations (only in create / update multiple mutations) */
    tmpId?: Maybe<Scalars['String']>;
};

export type DeactivateUserInput = {
    /** The id of the user which will be deactivated */
    id: Scalars['ID'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeactivateUserPayload = {
    __typename?: 'deactivateUserPayload';
    user?: Maybe<User>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteChangeInput = {
    id: Scalars['ID'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteChangePayload = {
    __typename?: 'deleteChangePayload';
    change?: Maybe<Change>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteColorInput = {
    id: Scalars['ID'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteColorPayload = {
    __typename?: 'deleteColorPayload';
    color?: Maybe<Color>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteGroupInput = {
    id: Scalars['ID'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteGroupPayload = {
    __typename?: 'deleteGroupPayload';
    group?: Maybe<Group>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteKeyFigureCategoryInput = {
    id: Scalars['ID'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteKeyFigureCategoryPayload = {
    __typename?: 'deleteKeyFigureCategoryPayload';
    keyFigureCategory?: Maybe<KeyFigureCategory>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteKeyFigureInput = {
    id: Scalars['ID'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteKeyFigurePayload = {
    __typename?: 'deleteKeyFigurePayload';
    keyFigure?: Maybe<KeyFigure>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteKeyFigureValueInput = {
    id: Scalars['ID'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteKeyFigureValuePayload = {
    __typename?: 'deleteKeyFigureValuePayload';
    keyFigureValue?: Maybe<KeyFigureValue>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteLocationInput = {
    id: Scalars['ID'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteLocationPayload = {
    __typename?: 'deleteLocationPayload';
    location?: Maybe<Location>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteMaterialGroupFieldRuleInput = {
    id: Scalars['ID'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteMaterialGroupFieldRulePayload = {
    __typename?: 'deleteMaterialGroupFieldRulePayload';
    materialGroupFieldRule?: Maybe<MaterialGroupFieldRule>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteMaterialGroupInput = {
    id: Scalars['ID'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteMaterialGroupPayload = {
    __typename?: 'deleteMaterialGroupPayload';
    materialGroup?: Maybe<MaterialGroup>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteMaterialGroupPropertyTypeRuleInput = {
    id: Scalars['ID'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteMaterialGroupPropertyTypeRulePayload = {
    __typename?: 'deleteMaterialGroupPropertyTypeRulePayload';
    materialGroupPropertyTypeRule?: Maybe<MaterialGroupPropertyTypeRule>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteMaterialImageInput = {
    id: Scalars['ID'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteMaterialImagePayload = {
    __typename?: 'deleteMaterialImagePayload';
    materialImage?: Maybe<MaterialImage>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteMaterialInput = {
    id: Scalars['ID'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteMaterialPayload = {
    __typename?: 'deleteMaterialPayload';
    material?: Maybe<Material>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteMaterialScopeInput = {
    id: Scalars['ID'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteMaterialScopePayload = {
    __typename?: 'deleteMaterialScopePayload';
    materialScope?: Maybe<MaterialScope>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeletePropertyInput = {
    id: Scalars['ID'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeletePropertyPayload = {
    __typename?: 'deletePropertyPayload';
    property?: Maybe<Property>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeletePropertyTypeInput = {
    id: Scalars['ID'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeletePropertyTypePayload = {
    __typename?: 'deletePropertyTypePayload';
    propertyType?: Maybe<PropertyType>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteRevisionInput = {
    id: Scalars['ID'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteRevisionPayload = {
    __typename?: 'deleteRevisionPayload';
    revision?: Maybe<Revision>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type MarkForDeletionMaterialInput = {
    /** The id of the material which will be deleted */
    id: Scalars['ID'];
    /** Rest mark for delete on null */
    reset?: Maybe<Scalars['Boolean']>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type MarkForDeletionMaterialPayload = {
    __typename?: 'markForDeletionMaterialPayload';
    material?: Maybe<Material>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type RefreshxLockResult = {
    __typename?: 'refreshxLockResult';
    lock?: Maybe<Scalars['ID']>;
};

export type ReleaseLockResult = {
    __typename?: 'releaseLockResult';
    status: Scalars['Boolean'];
};

export type RemoveUserInput = {
    /** The id of the user which will be deleted */
    id: Scalars['ID'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveUserPayload = {
    __typename?: 'removeUserPayload';
    user?: Maybe<User>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type ReviewRevisionInput = {
    id: Scalars['ID'];
    accept: Array<Scalars['ID']>;
    decline: Array<Scalars['ID']>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type ReviewRevisionPayload = {
    __typename?: 'reviewRevisionPayload';
    revision?: Maybe<Revision>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateChangeInput = {
    id: Scalars['ID'];
    revision?: Maybe<Scalars['String']>;
    field?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateChangePayload = {
    __typename?: 'updateChangePayload';
    change?: Maybe<Change>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateColorInput = {
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    color?: Maybe<Scalars['String']>;
    materials?: Maybe<Array<Maybe<Scalars['String']>>>;
    sort?: Maybe<Scalars['Int']>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateColorPayload = {
    __typename?: 'updateColorPayload';
    color?: Maybe<Color>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateGroupInput = {
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    externalLink?: Maybe<Scalars['String']>;
    users?: Maybe<Array<Maybe<Scalars['String']>>>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateGroupPayload = {
    __typename?: 'updateGroupPayload';
    group?: Maybe<Group>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateKeyFigureCategoryInput = {
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    keyFigures?: Maybe<Array<Maybe<Scalars['String']>>>;
    formArea?: Maybe<Scalars['String']>;
    additionalInformation?: Maybe<Scalars['String']>;
    sort?: Maybe<Scalars['Int']>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateKeyFigureCategoryPayload = {
    __typename?: 'updateKeyFigureCategoryPayload';
    keyFigureCategory?: Maybe<KeyFigureCategory>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateKeyFigureInput = {
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    symbol?: Maybe<Scalars['String']>;
    unit?: Maybe<Scalars['String']>;
    category?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    placeholderText?: Maybe<Scalars['String']>;
    sort?: Maybe<Scalars['Int']>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateKeyFigurePayload = {
    __typename?: 'updateKeyFigurePayload';
    keyFigure?: Maybe<KeyFigure>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateKeyFigureValueInput = {
    id: Scalars['ID'];
    lowerValue?: Maybe<Scalars['Float']>;
    upperValue?: Maybe<Scalars['Float']>;
    material?: Maybe<Scalars['String']>;
    keyFigure?: Maybe<Scalars['String']>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateKeyFigureValuePayload = {
    __typename?: 'updateKeyFigureValuePayload';
    keyFigureValue?: Maybe<KeyFigureValue>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateLocationInput = {
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    parent?: Maybe<Scalars['String']>;
    children?: Maybe<Array<Maybe<Scalars['String']>>>;
    materials?: Maybe<Array<Maybe<Scalars['String']>>>;
    sort?: Maybe<Scalars['Int']>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateLocationPayload = {
    __typename?: 'updateLocationPayload';
    location?: Maybe<Location>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateMaterialGroupFieldRuleInput = {
    id: Scalars['ID'];
    materialGroup?: Maybe<Scalars['String']>;
    field?: Maybe<Scalars['String']>;
    rule?: Maybe<Scalars['String']>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateMaterialGroupFieldRulePayload = {
    __typename?: 'updateMaterialGroupFieldRulePayload';
    materialGroupFieldRule?: Maybe<MaterialGroupFieldRule>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateMaterialGroupInput = {
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    code?: Maybe<Scalars['String']>;
    materials?: Maybe<Array<Maybe<Scalars['String']>>>;
    parent?: Maybe<Scalars['String']>;
    children?: Maybe<Array<Maybe<Scalars['String']>>>;
    fieldRules?: Maybe<Array<Maybe<Scalars['String']>>>;
    propertyTypeRules?: Maybe<Array<Maybe<Scalars['String']>>>;
    sort?: Maybe<Scalars['Int']>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateMaterialGroupPayload = {
    __typename?: 'updateMaterialGroupPayload';
    materialGroup?: Maybe<MaterialGroup>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateMaterialGroupPropertyTypeRuleInput = {
    id: Scalars['ID'];
    materialGroup?: Maybe<Scalars['String']>;
    propertyType?: Maybe<Scalars['String']>;
    rule?: Maybe<Scalars['String']>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateMaterialGroupPropertyTypeRulePayload = {
    __typename?: 'updateMaterialGroupPropertyTypeRulePayload';
    materialGroupPropertyTypeRule?: Maybe<MaterialGroupPropertyTypeRule>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateMaterialImageInput = {
    id: Scalars['ID'];
    material?: Maybe<Scalars['String']>;
    caption?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateMaterialImagePayload = {
    __typename?: 'updateMaterialImagePayload';
    materialImage?: Maybe<MaterialImage>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateMaterialInput = {
    id: Scalars['ID'];
    active?: Maybe<Scalars['Boolean']>;
    /** Materialname */
    name?: Maybe<Scalars['String']>;
    /** Wissenschaftliche Bezeichnungen */
    scientificName?: Maybe<Scalars['String']>;
    /** Weitere Bezeichnungen */
    additionalNames?: Maybe<Scalars['String']>;
    /** Materialuntergruppe, über parent property Zugriff auf Materialgruppe und Materialklasse */
    category?: Maybe<Scalars['String']>;
    /** Anwendungsbereich */
    scopes?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Anwendung, Ergänzung */
    additionalScopes?: Maybe<Scalars['String']>;
    /** Beschreibung */
    description?: Maybe<Scalars['String']>;
    /** Herkunft, Entstehung, Herstellung, geografisch */
    locations?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Wachstum / Kultivierung */
    cultivation?: Maybe<Scalars['String']>;
    /** Entstehung */
    origin?: Maybe<Scalars['String']>;
    /** Gewinnung */
    extraction?: Maybe<Scalars['String']>;
    /** Herstellung */
    production?: Maybe<Scalars['String']>;
    /** Weitere Verarbeitung */
    specialProcessing?: Maybe<Scalars['String']>;
    /** Revisionen: Nach Änderungen an Materialien werden neue Daten nicht sofort veröffentlicht sondern als Revision abgelegt. Diese können mit spezillen Berechtigungen freigegeben werden. */
    revisions?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Kennzahlen (Mechanik / Thermodynamik) */
    keyFigures?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Geruch */
    smell?: Maybe<Scalars['String']>;
    /** Eigenschaften aus verschiedenen Bereichen (Optik, Struktur, Textur, Oberfläche / Haptik, Format / Dimension, Beständigkeit, ...) */
    properties?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Farbbereich */
    colors?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Farbbesonderheiten */
    additionalColorInformation?: Maybe<Scalars['String']>;
    /** Dauerhaftigkeit */
    durability?: Maybe<Scalars['String']>;
    /** Brandverhalten, Ergänzung */
    fireBehaviorAdditions?: Maybe<Scalars['String']>;
    /** Lebensdauer nach BKI */
    durabilityBKI?: Maybe<Scalars['String']>;
    /** Nutzungsdauer Jahre nach BNB */
    durabilityBNB?: Maybe<Scalars['String']>;
    /** Risiko für die lokale Umwelt */
    localEnvironmentRisk?: Maybe<Scalars['String']>;
    /** Ökobilanz, Bezug */
    ecologicalBalanceReference?: Maybe<Scalars['String']>;
    /** Kreislauffähigkeit */
    recyclability?: Maybe<Scalars['String']>;
    /** Anmerkungen zur Ökobilanz */
    ecologyAdditionalInformation?: Maybe<Scalars['String']>;
    images?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Verbindungstechniken, Ergänzung */
    specialCoupling?: Maybe<Scalars['String']>;
    /** Mechanik */
    mechanic?: Maybe<Scalars['String']>;
    /** Thermodynamik */
    thermodynamic?: Maybe<Scalars['String']>;
    /** Beständigkeit */
    resistance?: Maybe<Scalars['String']>;
    /** Gebrauchstechnik */
    usageTechnology?: Maybe<Scalars['String']>;
    /** Akustik */
    acoustic?: Maybe<Scalars['String']>;
    /** Sponsor BUW */
    sponsorBUW?: Maybe<Scalars['String']>;
    /** Sponsor KIT */
    sponsorKIT?: Maybe<Scalars['String']>;
    /** Sponsor MSA */
    sponsorMSA?: Maybe<Scalars['String']>;
    /** Signatur BUW */
    signatureBUW?: Maybe<Scalars['String']>;
    /** Signatur KIT */
    signatureKIT?: Maybe<Scalars['String']>;
    /** Signatur MSA */
    signatureMSA?: Maybe<Scalars['String']>;
    /** Copyright Bilddateien */
    copyrightImages?: Maybe<Scalars['String']>;
    /** Copyright der Grafiken */
    copyrightGraphics?: Maybe<Scalars['String']>;
    sort?: Maybe<Scalars['Int']>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateMaterialPayload = {
    __typename?: 'updateMaterialPayload';
    material?: Maybe<Material>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateMaterialScopeInput = {
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    materials?: Maybe<Array<Maybe<Scalars['String']>>>;
    sort?: Maybe<Scalars['Int']>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateMaterialScopePayload = {
    __typename?: 'updateMaterialScopePayload';
    materialScope?: Maybe<MaterialScope>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePropertyInput = {
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    materials?: Maybe<Array<Maybe<Scalars['String']>>>;
    type?: Maybe<Scalars['String']>;
    image?: Maybe<Scalars['String']>;
    color?: Maybe<Scalars['String']>;
    sort?: Maybe<Scalars['Int']>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePropertyPayload = {
    __typename?: 'updatePropertyPayload';
    property?: Maybe<Property>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePropertyTypeInput = {
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    category?: Maybe<Scalars['String']>;
    properties?: Maybe<Array<Maybe<Scalars['String']>>>;
    allowMultiple?: Maybe<Scalars['Boolean']>;
    materialGroupRules?: Maybe<Array<Maybe<Scalars['String']>>>;
    defaultFormRule?: Maybe<Scalars['String']>;
    sort?: Maybe<Scalars['Int']>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePropertyTypePayload = {
    __typename?: 'updatePropertyTypePayload';
    propertyType?: Maybe<PropertyType>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateRevisionInput = {
    id: Scalars['ID'];
    changes?: Maybe<Array<Maybe<Scalars['String']>>>;
    material?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateRevisionPayload = {
    __typename?: 'updateRevisionPayload';
    revision?: Maybe<Revision>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateUserInput = {
    id: Scalars['ID'];
    email?: Maybe<Scalars['String']>;
    roles?: Maybe<Scalars['Iterable']>;
    groups?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Organisation: KIT, BUW, FUH usw. */
    organization?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
    revisions?: Maybe<Array<Maybe<Scalars['String']>>>;
    reviewedRevisions?: Maybe<Array<Maybe<Scalars['String']>>>;
    createdMaterials?: Maybe<Array<Maybe<Scalars['String']>>>;
    lastUpdatedMaterials?: Maybe<Array<Maybe<Scalars['String']>>>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateUserPayload = {
    __typename?: 'updateUserPayload';
    user?: Maybe<User>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UploadMaterialImageInput = {
    /** The file to upload */
    file: Scalars['Upload'];
    /** Type to categorize the image */
    type: Scalars['String'];
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UploadMaterialImagePayload = {
    __typename?: 'uploadMaterialImagePayload';
    materialImage?: Maybe<UploadMaterialImagePayloadData>;
    clientMutationId?: Maybe<Scalars['String']>;
};

export type UploadMaterialImagePayloadData = Node & {
    __typename?: 'uploadMaterialImagePayloadData';
    id: Scalars['ID'];
    filePath?: Maybe<MaterialImageResource>;
};

export type CreateGroupMutationVariables = Exact<{
    group: CreateGroupWithTempIdInput;
    newUsers: Array<CreateUserWithTempIdInput> | CreateUserWithTempIdInput;
}>;

export type CreateGroupMutation = { __typename?: 'Mutation' } & {
    createMultipleGroups: Array<{ __typename?: 'Group' } & GroupDetailsFragmentFragment>;
    createMultipleUsers: Array<{ __typename?: 'User' } & Pick<User, 'id'>>;
};

export type GroupDetailsFragmentFragment = { __typename?: 'Group' } & Pick<
    Group,
    'id' | 'name' | 'description' | 'externalLink'
> & {
        users?: Maybe<
            { __typename?: 'UserCursorConnection' } & Pick<UserCursorConnection, 'totalCount'> & {
                    edges?: Maybe<
                        Array<
                            Maybe<
                                { __typename?: 'UserEdge' } & {
                                    node?: Maybe<
                                        { __typename?: 'User' } & Pick<
                                            User,
                                            'id' | 'firstName' | 'lastName' | 'username'
                                        >
                                    >;
                                }
                            >
                        >
                    >;
                }
        >;
    };

export type GroupDetailsQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GroupDetailsQuery = { __typename?: 'Query' } & {
    group?: Maybe<{ __typename?: 'Group' } & GroupDetailsFragmentFragment>;
};

export type GroupsQueryVariables = Exact<{
    afterCursor?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
}>;

export type GroupsQuery = { __typename?: 'Query' } & {
    groups?: Maybe<
        { __typename?: 'GroupCursorConnection' } & Pick<GroupCursorConnection, 'totalCount'> & {
                edges?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'GroupEdge' } & {
                                node?: Maybe<
                                    { __typename?: 'Group' } & Pick<Group, 'id' | 'name'> & {
                                            users?: Maybe<
                                                { __typename?: 'UserCursorConnection' } & Pick<
                                                    UserCursorConnection,
                                                    'totalCount'
                                                >
                                            >;
                                        }
                                >;
                            }
                        >
                    >
                >;
                pageInfo: { __typename?: 'GroupPageInfo' } & Pick<
                    GroupPageInfo,
                    'hasNextPage' | 'endCursor' | 'hasPreviousPage' | 'startCursor'
                >;
            }
    >;
};

export type UpdateGroupMutationVariables = Exact<{
    group: UpdateGroupInput;
    newUsers: Array<CreateUserWithTempIdInput> | CreateUserWithTempIdInput;
}>;

export type UpdateGroupMutation = { __typename?: 'Mutation' } & {
    updateGroup?: Maybe<
        { __typename?: 'updateGroupPayload' } & {
            group?: Maybe<{ __typename?: 'Group' } & GroupDetailsFragmentFragment>;
        }
    >;
    createMultipleUsers: Array<{ __typename?: 'User' } & Pick<User, 'id'>>;
};

export type UsersQueryVariables = Exact<{
    afterCursor?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
}>;

export type UsersQuery = { __typename?: 'Query' } & {
    users?: Maybe<
        { __typename?: 'UserCursorConnection' } & Pick<UserCursorConnection, 'totalCount'> & {
                edges?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'UserEdge' } & {
                                node?: Maybe<
                                    { __typename?: 'User' } & Pick<
                                        User,
                                        'id' | 'username' | 'firstName' | 'lastName'
                                    > & {
                                            groups?: Maybe<
                                                { __typename?: 'GroupCursorConnection' } & {
                                                    edges?: Maybe<
                                                        Array<
                                                            Maybe<
                                                                { __typename?: 'GroupEdge' } & {
                                                                    node?: Maybe<
                                                                        { __typename?: 'Group' } & Pick<Group, 'name'>
                                                                    >;
                                                                }
                                                            >
                                                        >
                                                    >;
                                                }
                                            >;
                                        }
                                >;
                            }
                        >
                    >
                >;
            }
    >;
};

export type AdminMaterialGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type AdminMaterialGroupsQuery = { __typename?: 'Query' } & {
    materialGroups?: Maybe<
        Array<
            Maybe<
                { __typename?: 'MaterialGroup' } & Pick<MaterialGroup, 'id' | 'name' | 'sort'> & {
                        parent?: Maybe<{ __typename?: 'MaterialGroup' } & Pick<MaterialGroup, 'id'>>;
                        children?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'MaterialGroup' } & Pick<MaterialGroup, 'id' | 'name' | 'sort'> & {
                                            parent?: Maybe<
                                                { __typename?: 'MaterialGroup' } & Pick<MaterialGroup, 'id'>
                                            >;
                                            children?: Maybe<
                                                Array<
                                                    Maybe<
                                                        { __typename?: 'MaterialGroup' } & Pick<
                                                            MaterialGroup,
                                                            'id' | 'name' | 'sort'
                                                        > & {
                                                                parent?: Maybe<
                                                                    { __typename?: 'MaterialGroup' } & Pick<
                                                                        MaterialGroup,
                                                                        'id'
                                                                    >
                                                                >;
                                                            }
                                                    >
                                                >
                                            >;
                                        }
                                >
                            >
                        >;
                    }
            >
        >
    >;
};

export type CreateMaterialGroupMutationVariables = Exact<{
    input: CreateMaterialGroupInput;
}>;

export type CreateMaterialGroupMutation = { __typename?: 'Mutation' } & {
    createMaterialGroup?: Maybe<
        { __typename?: 'createMaterialGroupPayload' } & {
            materialGroup?: Maybe<
                { __typename?: 'MaterialGroup' } & Pick<MaterialGroup, 'name' | 'id' | 'sort'> & {
                        parent?: Maybe<{ __typename?: 'MaterialGroup' } & Pick<MaterialGroup, 'id'>>;
                    }
            >;
        }
    >;
};

export type UpdateMaterialGroupMutationVariables = Exact<{
    input: UpdateMaterialGroupInput;
}>;

export type UpdateMaterialGroupMutation = { __typename?: 'Mutation' } & {
    updateMaterialGroup?: Maybe<
        { __typename?: 'updateMaterialGroupPayload' } & {
            materialGroup?: Maybe<
                { __typename?: 'MaterialGroup' } & Pick<MaterialGroup, 'name' | 'id' | 'sort'> & {
                        children?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'MaterialGroup' } & Pick<MaterialGroup, 'id' | 'name' | 'sort'> & {
                                            parent?: Maybe<
                                                { __typename?: 'MaterialGroup' } & Pick<MaterialGroup, 'id'>
                                            >;
                                            children?: Maybe<
                                                Array<
                                                    Maybe<
                                                        { __typename?: 'MaterialGroup' } & Pick<
                                                            MaterialGroup,
                                                            'id' | 'name' | 'sort'
                                                        >
                                                    >
                                                >
                                            >;
                                        }
                                >
                            >
                        >;
                    }
            >;
        }
    >;
};

export type DeleteMaterialGroupMutationVariables = Exact<{
    input: DeleteMaterialGroupInput;
}>;

export type DeleteMaterialGroupMutation = { __typename?: 'Mutation' } & {
    deleteMaterialGroup?: Maybe<
        { __typename?: 'deleteMaterialGroupPayload' } & {
            materialGroup?: Maybe<{ __typename?: 'MaterialGroup' } & Pick<MaterialGroup, 'id'>>;
        }
    >;
};

export type ColorDataFragment = { __typename: 'Color' } & Pick<Color, 'id' | 'name' | 'color' | 'sort'>;

export type CreateColorMutationVariables = Exact<{
    input: CreateColorInput;
}>;

export type CreateColorMutation = { __typename?: 'Mutation' } & {
    createColor?: Maybe<
        { __typename?: 'createColorPayload' } & { color?: Maybe<{ __typename?: 'Color' } & ColorDataFragment> }
    >;
};

export type UpdateColorMutationVariables = Exact<{
    input: UpdateColorInput;
}>;

export type UpdateColorMutation = { __typename?: 'Mutation' } & {
    updateColor?: Maybe<
        { __typename?: 'updateColorPayload' } & { color?: Maybe<{ __typename?: 'Color' } & ColorDataFragment> }
    >;
};

export type DeleteColorMutationVariables = Exact<{
    input: DeleteColorInput;
}>;

export type DeleteColorMutation = { __typename?: 'Mutation' } & {
    deleteColor?: Maybe<
        { __typename?: 'deleteColorPayload' } & { color?: Maybe<{ __typename?: 'Color' } & Pick<Color, 'id'>> }
    >;
};

export type ColorsQueryVariables = Exact<{ [key: string]: never }>;

export type ColorsQuery = { __typename?: 'Query' } & {
    colors?: Maybe<Array<Maybe<{ __typename?: 'Color' } & ColorDataFragment>>>;
};

export type LocationsQueryVariables = Exact<{ [key: string]: never }>;

export type LocationsQuery = { __typename?: 'Query' } & {
    locations?: Maybe<
        Array<
            Maybe<
                { __typename?: 'Location' } & Pick<Location, 'id' | 'name' | 'sort'> & {
                        parent?: Maybe<{ __typename?: 'Location' } & Pick<Location, 'id'>>;
                        children?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'Location' } & Pick<Location, 'id' | 'name' | 'sort'> & {
                                            parent?: Maybe<{ __typename?: 'Location' } & Pick<Location, 'id'>>;
                                            children?: Maybe<
                                                Array<
                                                    Maybe<
                                                        { __typename?: 'Location' } & Pick<
                                                            Location,
                                                            'id' | 'name' | 'sort'
                                                        > & {
                                                                parent?: Maybe<
                                                                    { __typename?: 'Location' } & Pick<Location, 'id'>
                                                                >;
                                                                children?: Maybe<
                                                                    Array<
                                                                        Maybe<
                                                                            { __typename?: 'Location' } & Pick<
                                                                                Location,
                                                                                'id' | 'name' | 'sort'
                                                                            > & {
                                                                                    parent?: Maybe<
                                                                                        {
                                                                                            __typename?: 'Location';
                                                                                        } & Pick<Location, 'id'>
                                                                                    >;
                                                                                    children?: Maybe<
                                                                                        Array<
                                                                                            Maybe<
                                                                                                {
                                                                                                    __typename?: 'Location';
                                                                                                } & Pick<
                                                                                                    Location,
                                                                                                    | 'id'
                                                                                                    | 'name'
                                                                                                    | 'sort'
                                                                                                > & {
                                                                                                        parent?: Maybe<
                                                                                                            {
                                                                                                                __typename?: 'Location';
                                                                                                            } & Pick<
                                                                                                                Location,
                                                                                                                'id'
                                                                                                            >
                                                                                                        >;
                                                                                                    }
                                                                                            >
                                                                                        >
                                                                                    >;
                                                                                }
                                                                        >
                                                                    >
                                                                >;
                                                            }
                                                    >
                                                >
                                            >;
                                        }
                                >
                            >
                        >;
                    }
            >
        >
    >;
};

export type CreateLocationMutationVariables = Exact<{
    input: CreateLocationInput;
}>;

export type CreateLocationMutation = { __typename?: 'Mutation' } & {
    createLocation?: Maybe<
        { __typename?: 'createLocationPayload' } & {
            location?: Maybe<
                { __typename?: 'Location' } & Pick<Location, 'name' | 'id' | 'sort'> & {
                        parent?: Maybe<{ __typename?: 'Location' } & Pick<Location, 'id'>>;
                    }
            >;
        }
    >;
};

export type UpdateLocationMutationVariables = Exact<{
    input: UpdateLocationInput;
}>;

export type UpdateLocationMutation = { __typename?: 'Mutation' } & {
    updateLocation?: Maybe<
        { __typename?: 'updateLocationPayload' } & {
            location?: Maybe<
                { __typename?: 'Location' } & Pick<Location, 'name' | 'id' | 'sort'> & {
                        children?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'Location' } & Pick<Location, 'id' | 'name'> & {
                                            parent?: Maybe<{ __typename?: 'Location' } & Pick<Location, 'id'>>;
                                            children?: Maybe<
                                                Array<
                                                    Maybe<{ __typename?: 'Location' } & Pick<Location, 'id' | 'name'>>
                                                >
                                            >;
                                        }
                                >
                            >
                        >;
                    }
            >;
        }
    >;
};

export type DeleteLocationMutationVariables = Exact<{
    input: DeleteLocationInput;
}>;

export type DeleteLocationMutation = { __typename?: 'Mutation' } & {
    deleteLocation?: Maybe<
        { __typename?: 'deleteLocationPayload' } & {
            location?: Maybe<{ __typename?: 'Location' } & Pick<Location, 'id'>>;
        }
    >;
};

export type MaterialScopeDataFragment = { __typename: 'MaterialScope' } & Pick<MaterialScope, 'id' | 'name' | 'sort'>;

export type CreateMaterialScopeMutationVariables = Exact<{
    input: CreateMaterialScopeInput;
}>;

export type CreateMaterialScopeMutation = { __typename?: 'Mutation' } & {
    createMaterialScope?: Maybe<
        { __typename?: 'createMaterialScopePayload' } & {
            materialScope?: Maybe<{ __typename?: 'MaterialScope' } & MaterialScopeDataFragment>;
        }
    >;
};

export type UpdateMaterialScopeMutationVariables = Exact<{
    input: UpdateMaterialScopeInput;
}>;

export type UpdateMaterialScopeMutation = { __typename?: 'Mutation' } & {
    updateMaterialScope?: Maybe<
        { __typename?: 'updateMaterialScopePayload' } & {
            materialScope?: Maybe<{ __typename?: 'MaterialScope' } & MaterialScopeDataFragment>;
        }
    >;
};

export type DeleteMaterialScopeMutationVariables = Exact<{
    input: DeleteMaterialScopeInput;
}>;

export type DeleteMaterialScopeMutation = { __typename?: 'Mutation' } & {
    deleteMaterialScope?: Maybe<
        { __typename?: 'deleteMaterialScopePayload' } & {
            materialScope?: Maybe<{ __typename?: 'MaterialScope' } & Pick<MaterialScope, 'id'>>;
        }
    >;
};

export type PropertyDataFragment = { __typename: 'Property' } & Pick<Property, 'id' | 'name' | 'sort'> & {
        type: { __typename?: 'PropertyType' } & Pick<PropertyType, 'id'>;
    };

export type CreatePropertyMutationVariables = Exact<{
    input: CreatePropertyInput;
}>;

export type CreatePropertyMutation = { __typename?: 'Mutation' } & {
    createProperty?: Maybe<
        { __typename?: 'createPropertyPayload' } & {
            property?: Maybe<{ __typename?: 'Property' } & PropertyDataFragment>;
        }
    >;
};

export type UpdatePropertyMutationVariables = Exact<{
    input: UpdatePropertyInput;
}>;

export type UpdatePropertyMutation = { __typename?: 'Mutation' } & {
    updateProperty?: Maybe<
        { __typename?: 'updatePropertyPayload' } & {
            property?: Maybe<{ __typename?: 'Property' } & PropertyDataFragment>;
        }
    >;
};

export type DeletePropertyMutationVariables = Exact<{
    input: DeletePropertyInput;
}>;

export type DeletePropertyMutation = { __typename?: 'Mutation' } & {
    deleteProperty?: Maybe<
        { __typename?: 'deletePropertyPayload' } & {
            property?: Maybe<{ __typename?: 'Property' } & Pick<Property, 'id'>>;
        }
    >;
};

export type PropertyTypeDataFragment = { __typename?: 'PropertyType' } & Pick<
    PropertyType,
    'id' | 'name' | 'category'
> & {
        properties?: Maybe<
            Array<
                Maybe<
                    { __typename?: 'Property' } & Pick<Property, 'id' | 'name' | 'sort'> & {
                            type: { __typename?: 'PropertyType' } & Pick<PropertyType, 'id'>;
                        }
                >
            >
        >;
    };

export type PropertyTypeQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type PropertyTypeQuery = { __typename?: 'Query' } & {
    propertyType?: Maybe<{ __typename: 'PropertyType' } & PropertyTypeDataFragment>;
};

export type PropertyTypesQueryVariables = Exact<{ [key: string]: never }>;

export type PropertyTypesQuery = { __typename?: 'Query' } & {
    propertyTypes?: Maybe<
        Array<
            Maybe<
                { __typename?: 'PropertyType' } & Pick<PropertyType, 'id' | 'name' | 'category'> & {
                        properties?: Maybe<Array<Maybe<{ __typename?: 'Property' } & Pick<Property, 'id' | 'name'>>>>;
                    }
            >
        >
    >;
};

export type AdminChangePasswordMutationVariables = Exact<{
    newPassword: Scalars['String'];
    userId: Scalars['ID'];
}>;

export type AdminChangePasswordMutation = { __typename?: 'Mutation' } & {
    adminChangePasswordUser?: Maybe<
        { __typename?: 'adminChangePasswordUserPayload' } & { user?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>> }
    >;
};

export type CountUsersByMailQueryVariables = Exact<{
    email?: Maybe<Scalars['String']>;
}>;

export type CountUsersByMailQuery = { __typename?: 'Query' } & {
    users?: Maybe<{ __typename?: 'UserCursorConnection' } & Pick<UserCursorConnection, 'totalCount'>>;
};

export type CreateUserMutationVariables = Exact<{
    input: CreateUserInput;
}>;

export type CreateUserMutation = { __typename?: 'Mutation' } & {
    createUser?: Maybe<
        { __typename?: 'createUserPayload' } & { user?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>> }
    >;
};

export type UpdateUserMutationVariables = Exact<{
    input: UpdateUserInput;
}>;

export type UpdateUserMutation = { __typename?: 'Mutation' } & {
    updateUser?: Maybe<
        { __typename?: 'updateUserPayload' } & { user?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>> }
    >;
};

export type UserListQueryVariables = Exact<{ [key: string]: never }>;

export type UserListQuery = { __typename?: 'Query' } & {
    users?: Maybe<
        { __typename?: 'UserCursorConnection' } & {
            edges?: Maybe<
                Array<
                    Maybe<
                        { __typename?: 'UserEdge' } & {
                            node?: Maybe<
                                { __typename?: 'User' } & Pick<
                                    User,
                                    'id' | 'email' | 'firstName' | 'lastName' | 'roles'
                                >
                            >;
                        }
                    >
                >
            >;
        }
    >;
};

export type UserQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type UserQuery = { __typename?: 'Query' } & {
    user?: Maybe<
        { __typename?: 'User' } & Pick<User, 'id' | 'email' | 'roles' | 'firstName' | 'lastName' | 'organization'>
    >;
};

export type RemoveUserMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type RemoveUserMutation = { __typename?: 'Mutation' } & {
    removeUser?: Maybe<
        { __typename?: 'removeUserPayload' } & { user?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>> }
    >;
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = { __typename?: 'Query' } & {
    meUser?: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'username' | 'email' | 'roles'>>;
};

export type RuleConfigurationFragment = { __typename?: 'MaterialGroup' } & {
    fieldRules?: Maybe<
        Array<Maybe<{ __typename?: 'MaterialGroupFieldRule' } & Pick<MaterialGroupFieldRule, 'id' | 'field' | 'rule'>>>
    >;
    propertyTypeRules?: Maybe<
        Array<
            Maybe<
                { __typename?: 'MaterialGroupPropertyTypeRule' } & Pick<
                    MaterialGroupPropertyTypeRule,
                    'id' | 'rule'
                > & { propertyType: { __typename?: 'PropertyType' } & Pick<PropertyType, 'id'> }
            >
        >
    >;
};

export type FormRulesQueryVariables = Exact<{
    materialGroup: Scalars['ID'];
}>;

export type FormRulesQuery = { __typename?: 'Query' } & {
    materialGroup?: Maybe<
        { __typename?: 'MaterialGroup' } & Pick<MaterialGroup, 'id'> & {
                parent?: Maybe<
                    { __typename?: 'MaterialGroup' } & Pick<MaterialGroup, 'id'> & {
                            parent?: Maybe<
                                { __typename?: 'MaterialGroup' } & Pick<MaterialGroup, 'id'> & {
                                        parent?: Maybe<
                                            { __typename?: 'MaterialGroup' } & Pick<MaterialGroup, 'id'> & {
                                                    parent?: Maybe<
                                                        { __typename?: 'MaterialGroup' } & Pick<MaterialGroup, 'id'> & {
                                                                parent?: Maybe<
                                                                    { __typename?: 'MaterialGroup' } & Pick<
                                                                        MaterialGroup,
                                                                        'id'
                                                                    > &
                                                                        RuleConfigurationFragment
                                                                >;
                                                            } & RuleConfigurationFragment
                                                    >;
                                                } & RuleConfigurationFragment
                                        >;
                                    } & RuleConfigurationFragment
                            >;
                        } & RuleConfigurationFragment
                >;
            } & RuleConfigurationFragment
    >;
};

export type MaterialScopeNameQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type MaterialScopeNameQuery = { __typename?: 'Query' } & {
    materialScope?: Maybe<{ __typename?: 'MaterialScope' } & Pick<MaterialScope, 'id' | 'name'>>;
};

export type ColorNameQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type ColorNameQuery = { __typename?: 'Query' } & {
    color?: Maybe<{ __typename?: 'Color' } & Pick<Color, 'id' | 'name' | 'color'>>;
};

export type KeyFigureMetadataQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type KeyFigureMetadataQuery = { __typename?: 'Query' } & {
    keyFigure?: Maybe<
        { __typename?: 'KeyFigure' } & Pick<KeyFigure, 'id' | 'name' | 'symbol' | 'unit' | 'type'> & {
                category: { __typename?: 'KeyFigureCategory' } & Pick<KeyFigureCategory, 'id' | 'name'>;
            }
    >;
};

export type PropertyMetadataQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type PropertyMetadataQuery = { __typename?: 'Query' } & {
    property?: Maybe<
        { __typename?: 'Property' } & Pick<Property, 'id' | 'name' | 'image'> & {
                type: { __typename?: 'PropertyType' } & Pick<
                    PropertyType,
                    'id' | 'name' | 'category' | 'allowMultiple'
                >;
            }
    >;
};

export type CreateMaterialMutationVariables = Exact<{
    material: CreateMaterialWithTempIdInput;
    newKeyFigures: Array<CreateKeyFigureValueWithTempIdInput> | CreateKeyFigureValueWithTempIdInput;
    updatedImages: Array<UpdateMaterialImageInput> | UpdateMaterialImageInput;
}>;

export type CreateMaterialMutation = { __typename?: 'Mutation' } & {
    createMultipleMaterials: Array<{ __typename: 'Material' } & MaterialResolverDataFragment>;
    updateMultipleMaterialImages: Array<{ __typename?: 'MaterialImage' } & Pick<MaterialImage, 'id' | 'caption'>>;
    createMultipleKeyFigureValues: Array<{ __typename?: 'KeyFigureValue' } & Pick<KeyFigureValue, 'id'>>;
};

export type UpdateMaterialMutationVariables = Exact<{
    material: UpdateMaterialInput;
    newKeyFigures: Array<CreateKeyFigureValueWithTempIdInput> | CreateKeyFigureValueWithTempIdInput;
    updatedKeyFigures: Array<UpdateKeyFigureValueInput> | UpdateKeyFigureValueInput;
    deletedKeyFigures: Array<Scalars['ID']> | Scalars['ID'];
    updatedImages: Array<UpdateMaterialImageInput> | UpdateMaterialImageInput;
}>;

export type UpdateMaterialMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteMultipleKeyFigureValues'> & {
        updateMaterial?: Maybe<
            { __typename?: 'updateMaterialPayload' } & {
                material?: Maybe<{ __typename: 'Material' } & Pick<Material, 'id'>>;
            }
        >;
        updateMultipleKeyFigureValues: Array<{ __typename?: 'KeyFigureValue' } & Pick<KeyFigureValue, 'id'>>;
        createMultipleKeyFigureValues: Array<{ __typename?: 'KeyFigureValue' } & Pick<KeyFigureValue, 'id'>>;
        updateMultipleMaterialImages: Array<{ __typename?: 'MaterialImage' } & Pick<MaterialImage, 'id' | 'caption'>>;
    };

export type CreateRevisionMutationVariables = Exact<{
    description: Scalars['String'];
    materialId: Scalars['String'];
    changes: Array<CreateChangeWithTempIdInput> | CreateChangeWithTempIdInput;
}>;

export type CreateRevisionMutation = { __typename?: 'Mutation' } & {
    createMultipleRevisions: Array<{ __typename?: 'Revision' } & Pick<Revision, 'id'>>;
    createMultipleChanges: Array<{ __typename?: 'Change' } & Pick<Change, 'id'>>;
};

export type UploadMaterialImageMutationVariables = Exact<{
    file: Scalars['Upload'];
    type: Scalars['String'];
}>;

export type UploadMaterialImageMutation = { __typename?: 'Mutation' } & {
    uploadMaterialImage?: Maybe<
        { __typename?: 'uploadMaterialImagePayload' } & {
            materialImage?: Maybe<
                { __typename?: 'uploadMaterialImagePayloadData' } & Pick<UploadMaterialImagePayloadData, 'id'>
            >;
        }
    >;
};

export type MarkForDeletionMaterialMutationVariables = Exact<{
    id: Scalars['ID'];
    reset?: Maybe<Scalars['Boolean']>;
}>;

export type MarkForDeletionMaterialMutation = { __typename?: 'Mutation' } & {
    markForDeletionMaterial?: Maybe<
        { __typename?: 'markForDeletionMaterialPayload' } & {
            material?: Maybe<{ __typename?: 'Material' } & Pick<Material, 'id' | 'markedForDeletionAt'>>;
        }
    >;
};

export type MaterialApplicationsQueryVariables = Exact<{ [key: string]: never }>;

export type MaterialApplicationsQuery = { __typename?: 'Query' } & {
    materialScopes?: Maybe<Array<Maybe<{ __typename?: 'MaterialScope' } & Pick<MaterialScope, 'id' | 'name'>>>>;
};

export type MaterialGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type MaterialGroupsQuery = { __typename?: 'Query' } & {
    materialGroups?: Maybe<
        Array<
            Maybe<
                { __typename?: 'MaterialGroup' } & Pick<MaterialGroup, 'id' | 'name'> & {
                        children?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'MaterialGroup' } & Pick<MaterialGroup, 'id' | 'name'> & {
                                            parent?: Maybe<
                                                { __typename?: 'MaterialGroup' } & Pick<MaterialGroup, 'id'>
                                            >;
                                            children?: Maybe<
                                                Array<
                                                    Maybe<
                                                        { __typename?: 'MaterialGroup' } & Pick<
                                                            MaterialGroup,
                                                            'id' | 'name'
                                                        >
                                                    >
                                                >
                                            >;
                                        }
                                >
                            >
                        >;
                    }
            >
        >
    >;
};

export type MaterialLocationsQueryVariables = Exact<{ [key: string]: never }>;

export type MaterialLocationsQuery = { __typename?: 'Query' } & {
    locations?: Maybe<
        Array<
            Maybe<
                { __typename?: 'Location' } & Pick<Location, 'id' | 'name'> & {
                        parent?: Maybe<{ __typename?: 'Location' } & Pick<Location, 'id'>>;
                        children?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'Location' } & Pick<Location, 'id' | 'name'> & {
                                            parent?: Maybe<{ __typename?: 'Location' } & Pick<Location, 'id'>>;
                                            children?: Maybe<
                                                Array<
                                                    Maybe<
                                                        { __typename?: 'Location' } & Pick<Location, 'id' | 'name'> & {
                                                                parent?: Maybe<
                                                                    { __typename?: 'Location' } & Pick<Location, 'id'>
                                                                >;
                                                                children?: Maybe<
                                                                    Array<
                                                                        Maybe<
                                                                            { __typename?: 'Location' } & Pick<
                                                                                Location,
                                                                                'id' | 'name'
                                                                            > & {
                                                                                    parent?: Maybe<
                                                                                        {
                                                                                            __typename?: 'Location';
                                                                                        } & Pick<Location, 'id'>
                                                                                    >;
                                                                                    children?: Maybe<
                                                                                        Array<
                                                                                            Maybe<
                                                                                                {
                                                                                                    __typename?: 'Location';
                                                                                                } & Pick<
                                                                                                    Location,
                                                                                                    'id' | 'name'
                                                                                                > & {
                                                                                                        parent?: Maybe<
                                                                                                            {
                                                                                                                __typename?: 'Location';
                                                                                                            } & Pick<
                                                                                                                Location,
                                                                                                                'id'
                                                                                                            >
                                                                                                        >;
                                                                                                    }
                                                                                            >
                                                                                        >
                                                                                    >;
                                                                                }
                                                                        >
                                                                    >
                                                                >;
                                                            }
                                                    >
                                                >
                                            >;
                                        }
                                >
                            >
                        >;
                    }
            >
        >
    >;
};

export type MaterialColorsQueryVariables = Exact<{ [key: string]: never }>;

export type MaterialColorsQuery = { __typename?: 'Query' } & {
    colors?: Maybe<Array<Maybe<{ __typename?: 'Color' } & Pick<Color, 'id' | 'name' | 'color'>>>>;
};

export type KeyFigureCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type KeyFigureCategoriesQuery = { __typename?: 'Query' } & {
    keyFigureCategories?: Maybe<
        Array<
            Maybe<
                { __typename?: 'KeyFigureCategory' } & Pick<
                    KeyFigureCategory,
                    'id' | 'name' | 'formArea' | 'additionalInformation'
                > & {
                        keyFigures?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'KeyFigure' } & Pick<
                                        KeyFigure,
                                        'id' | 'name' | 'symbol' | 'unit' | 'type' | 'placeholderText'
                                    > & {
                                            category: { __typename?: 'KeyFigureCategory' } & Pick<
                                                KeyFigureCategory,
                                                'id' | 'formArea'
                                            >;
                                        }
                                >
                            >
                        >;
                    }
            >
        >
    >;
};

export type MaterialPropertyTypesQueryVariables = Exact<{ [key: string]: never }>;

export type MaterialPropertyTypesQuery = { __typename?: 'Query' } & {
    propertyTypes?: Maybe<
        Array<
            Maybe<
                { __typename?: 'PropertyType' } & Pick<
                    PropertyType,
                    'id' | 'name' | 'category' | 'allowMultiple' | 'defaultFormRule'
                > & {
                        properties?: Maybe<
                            Array<
                                Maybe<{ __typename?: 'Property' } & Pick<Property, 'id' | 'name' | 'image' | 'color'>>
                            >
                        >;
                    }
            >
        >
    >;
};

export type MaterialsQueryVariables = Exact<{
    first?: Maybe<Scalars['Int']>;
    afterCursor?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['Int']>;
    name?: Maybe<Scalars['String']>;
    category?: Maybe<Scalars['String']>;
    active?: Maybe<Scalars['Boolean']>;
    isDeleted: Scalars['Boolean'];
}>;

export type MaterialsQuery = { __typename?: 'Query' } & {
    materials?: Maybe<
        { __typename?: 'MaterialCursorConnection' } & Pick<MaterialCursorConnection, 'totalCount'> & {
                edges?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'MaterialEdge' } & {
                                node?: Maybe<
                                    { __typename?: 'Material' } & Pick<
                                        Material,
                                        'id' | 'active' | 'name' | 'updatedAt'
                                    > & {
                                            author?: Maybe<
                                                { __typename?: 'User' } & Pick<
                                                    User,
                                                    'id' | 'firstName' | 'lastName' | 'organization'
                                                >
                                            >;
                                            lastEditor?: Maybe<
                                                { __typename?: 'User' } & Pick<
                                                    User,
                                                    'id' | 'firstName' | 'lastName' | 'organization'
                                                >
                                            >;
                                            category: { __typename?: 'MaterialGroup' } & Pick<
                                                MaterialGroup,
                                                'id' | 'name' | 'code'
                                            > & {
                                                    parent?: Maybe<
                                                        { __typename?: 'MaterialGroup' } & Pick<
                                                            MaterialGroup,
                                                            'id' | 'name' | 'code'
                                                        > & {
                                                                parent?: Maybe<
                                                                    { __typename?: 'MaterialGroup' } & Pick<
                                                                        MaterialGroup,
                                                                        'id' | 'name' | 'code'
                                                                    >
                                                                >;
                                                            }
                                                    >;
                                                };
                                        }
                                >;
                            }
                        >
                    >
                >;
            }
    >;
};

export type MaterialDataFragment = { __typename?: 'Material' } & Pick<
    Material,
    | 'id'
    | 'active'
    | 'isDeleted'
    | 'name'
    | 'scientificName'
    | 'additionalNames'
    | 'additionalScopes'
    | 'description'
    | 'cultivation'
    | 'origin'
    | 'extraction'
    | 'production'
    | 'specialProcessing'
    | 'specialCoupling'
    | 'smell'
    | 'fireBehaviorAdditions'
    | 'durability'
    | 'durabilityBKI'
    | 'durabilityBNB'
    | 'additionalColorInformation'
    | 'localEnvironmentRisk'
    | 'ecologicalBalanceReference'
    | 'recyclability'
    | 'ecologyAdditionalInformation'
    | 'mechanic'
    | 'thermodynamic'
    | 'resistance'
    | 'usageTechnology'
    | 'acoustic'
    | 'sponsorBUW'
    | 'sponsorKIT'
    | 'sponsorMSA'
    | 'signatureBUW'
    | 'signatureKIT'
    | 'signatureMSA'
    | 'copyrightImages'
    | 'copyrightGraphics'
> & {
        category: { __typename?: 'MaterialGroup' } & Pick<MaterialGroup, 'id' | 'name' | 'code'> & {
                parent?: Maybe<
                    { __typename?: 'MaterialGroup' } & Pick<MaterialGroup, 'id' | 'name' | 'code'> & {
                            parent?: Maybe<
                                { __typename?: 'MaterialGroup' } & Pick<MaterialGroup, 'id' | 'name' | 'code'> & {
                                        parent?: Maybe<
                                            { __typename?: 'MaterialGroup' } & Pick<
                                                MaterialGroup,
                                                'id' | 'name' | 'code'
                                            >
                                        >;
                                    }
                            >;
                        }
                >;
            };
        scopes?: Maybe<Array<Maybe<{ __typename?: 'MaterialScope' } & Pick<MaterialScope, 'id' | 'name'>>>>;
        locations?: Maybe<
            Array<
                Maybe<
                    { __typename?: 'Location' } & Pick<Location, 'id' | 'name'> & {
                            parent?: Maybe<
                                { __typename?: 'Location' } & Pick<Location, 'id' | 'name'> & {
                                        parent?: Maybe<
                                            { __typename?: 'Location' } & Pick<Location, 'id' | 'name'> & {
                                                    parent?: Maybe<
                                                        { __typename?: 'Location' } & Pick<Location, 'id' | 'name'> & {
                                                                parent?: Maybe<
                                                                    { __typename?: 'Location' } & Pick<
                                                                        Location,
                                                                        'id' | 'name'
                                                                    >
                                                                >;
                                                            }
                                                    >;
                                                }
                                        >;
                                    }
                            >;
                        }
                >
            >
        >;
        properties?: Maybe<
            Array<
                Maybe<
                    { __typename?: 'Property' } & Pick<Property, 'id' | 'name' | 'image' | 'color'> & {
                            type: { __typename?: 'PropertyType' } & Pick<
                                PropertyType,
                                'id' | 'sort' | 'name' | 'category' | 'allowMultiple'
                            >;
                        }
                >
            >
        >;
        colors?: Maybe<Array<Maybe<{ __typename?: 'Color' } & Pick<Color, 'id' | 'name' | 'color'>>>>;
        images?: Maybe<
            Array<
                Maybe<
                    { __typename?: 'MaterialImage' } & Pick<MaterialImage, 'id' | 'caption' | 'type'> & {
                            filePath?: Maybe<
                                { __typename?: 'MaterialImageResource' } & Pick<
                                    MaterialImageResource,
                                    'id' | 'small' | 'large' | 'original'
                                >
                            >;
                        }
                >
            >
        >;
    };

export type MaterialKeyFiguresResolverDataFragment = { __typename?: 'Material' } & {
    keyFigures?: Maybe<
        Array<
            Maybe<
                { __typename?: 'KeyFigureValue' } & Pick<KeyFigureValue, 'id' | 'lowerValue' | 'upperValue'> & {
                        keyFigure: { __typename?: 'KeyFigure' } & Pick<
                            KeyFigure,
                            'id' | 'name' | 'symbol' | 'unit' | 'type'
                        > & {
                                category: { __typename?: 'KeyFigureCategory' } & Pick<
                                    KeyFigureCategory,
                                    'id' | 'name' | 'sort' | 'formArea'
                                >;
                            };
                    }
            >
        >
    >;
};

export type MaterialResolverDataFragment = { __typename?: 'Material' } & MaterialDataFragment &
    MaterialKeyFiguresResolverDataFragment;

export type MaterialQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type MaterialQuery = { __typename?: 'Query' } & {
    material?: Maybe<{ __typename: 'Material' } & MaterialDataFragment>;
};

export type KeyFiguresByMaterialidQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type KeyFiguresByMaterialidQuery = { __typename?: 'Query' } & {
    material?: Maybe<{ __typename: 'Material' } & MaterialKeyFiguresResolverDataFragment>;
};

export type GetMaterialScopeByIdQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetMaterialScopeByIdQuery = { __typename?: 'Query' } & {
    materialScope?: Maybe<{ __typename: 'MaterialScope' } & Pick<MaterialScope, 'id' | 'name'>>;
};

export type GetLocationByIdQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetLocationByIdQuery = { __typename?: 'Query' } & {
    location?: Maybe<
        { __typename: 'Location' } & Pick<Location, 'id' | 'name'> & {
                parent?: Maybe<
                    { __typename: 'Location' } & Pick<Location, 'id' | 'name'> & {
                            parent?: Maybe<
                                { __typename: 'Location' } & Pick<Location, 'id' | 'name'> & {
                                        parent?: Maybe<
                                            { __typename: 'Location' } & Pick<Location, 'id' | 'name'> & {
                                                    parent?: Maybe<
                                                        { __typename: 'Location' } & Pick<Location, 'id' | 'name'>
                                                    >;
                                                }
                                        >;
                                    }
                            >;
                        }
                >;
            }
    >;
};

export type GetPropertyByIdQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetPropertyByIdQuery = { __typename?: 'Query' } & {
    property?: Maybe<
        { __typename: 'Property' } & Pick<Property, 'id' | 'name' | 'image' | 'color'> & {
                type: { __typename?: 'PropertyType' } & Pick<
                    PropertyType,
                    'id' | 'sort' | 'name' | 'category' | 'allowMultiple'
                >;
            }
    >;
};

export type GetColorByIdQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetColorByIdQuery = { __typename?: 'Query' } & {
    color?: Maybe<{ __typename: 'Color' } & Pick<Color, 'id' | 'name' | 'color'>>;
};

export type GetImageByIdQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetImageByIdQuery = { __typename?: 'Query' } & {
    materialImage?: Maybe<
        { __typename: 'MaterialImage' } & Pick<MaterialImage, 'id' | 'caption' | 'type'> & {
                filePath?: Maybe<
                    { __typename?: 'MaterialImageResource' } & Pick<
                        MaterialImageResource,
                        'id' | 'small' | 'large' | 'original'
                    >
                >;
            }
    >;
};

export type MaterialRevisionsQueryVariables = Exact<{
    materialId: Scalars['ID'];
    afterCursor?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
}>;

export type MaterialRevisionsQuery = { __typename?: 'Query' } & {
    material?: Maybe<
        { __typename?: 'Material' } & Pick<Material, 'id'> & {
                revisions?: Maybe<
                    { __typename?: 'RevisionCursorConnection' } & Pick<RevisionCursorConnection, 'totalCount'> & {
                            edges?: Maybe<
                                Array<
                                    Maybe<
                                        { __typename?: 'RevisionEdge' } & {
                                            node?: Maybe<
                                                { __typename?: 'Revision' } & Pick<
                                                    Revision,
                                                    'id' | 'createdAt' | 'description' | 'applied'
                                                > & {
                                                        author: { __typename?: 'User' } & Pick<
                                                            User,
                                                            'id' | 'firstName' | 'lastName' | 'email'
                                                        >;
                                                        reviewer?: Maybe<
                                                            { __typename?: 'User' } & Pick<
                                                                User,
                                                                'id' | 'firstName' | 'lastName' | 'email'
                                                            >
                                                        >;
                                                    }
                                            >;
                                        }
                                    >
                                >
                            >;
                        }
                >;
            }
    >;
};

export type RevisionDetailDataFragment = { __typename?: 'Revision' } & Pick<
    Revision,
    'id' | 'createdAt' | 'description' | 'applied'
> & {
        author: { __typename?: 'User' } & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>;
        reviewer?: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>>;
        changes?: Maybe<
            Array<
                Maybe<
                    { __typename?: 'Change' } & Pick<
                        Change,
                        'id' | 'field' | 'value' | 'originalValue' | 'applied' | 'rejected'
                    >
                >
            >
        >;
    };

export type MaterialRevisionQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type MaterialRevisionQuery = { __typename?: 'Query' } & {
    revision?: Maybe<{ __typename: 'Revision' } & RevisionDetailDataFragment>;
};

export type ReviewRevisionMutationVariables = Exact<{
    revisionId: Scalars['ID'];
    acceptedChanges: Array<Scalars['ID']> | Scalars['ID'];
    declinedChanges: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type ReviewRevisionMutation = { __typename?: 'Mutation' } & {
    reviewRevision?: Maybe<
        { __typename?: 'reviewRevisionPayload' } & {
            revision?: Maybe<
                { __typename: 'Revision' } & {
                    material: { __typename: 'Material' } & MaterialResolverDataFragment;
                } & RevisionDetailDataFragment
            >;
        }
    >;
};

export type ChangePasswordMutationVariables = Exact<{
    currentPassword: Scalars['String'];
    newPassword: Scalars['String'];
    userId?: Maybe<Scalars['ID']>;
}>;

export type ChangePasswordMutation = { __typename?: 'Mutation' } & {
    changePasswordUser?: Maybe<
        { __typename?: 'changePasswordUserPayload' } & { user?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>> }
    >;
};

export const GroupDetailsFragmentFragmentDoc = gql`
    fragment GroupDetailsFragment on Group {
        id
        name
        description
        externalLink
        users(first: 1000) {
            edges {
                node {
                    id
                    firstName
                    lastName
                    username
                }
            }
            totalCount
        }
    }
`;
export const ColorDataFragmentDoc = gql`
    fragment ColorData on Color {
        id
        name
        color
        sort
        __typename
    }
`;
export const MaterialScopeDataFragmentDoc = gql`
    fragment MaterialScopeData on MaterialScope {
        id
        name
        sort
        __typename
    }
`;
export const PropertyDataFragmentDoc = gql`
    fragment PropertyData on Property {
        id
        name
        sort
        __typename
        type {
            id
        }
    }
`;
export const PropertyTypeDataFragmentDoc = gql`
    fragment PropertyTypeData on PropertyType {
        id
        name
        category
        properties(order: { sort: "ASC" }) {
            id
            name
            sort
            type {
                id
            }
        }
    }
`;
export const RuleConfigurationFragmentDoc = gql`
    fragment RuleConfiguration on MaterialGroup {
        fieldRules {
            id
            field
            rule
        }
        propertyTypeRules {
            id
            propertyType {
                id
            }
            rule
        }
    }
`;
export const MaterialDataFragmentDoc = gql`
    fragment MaterialData on Material {
        id
        active
        isDeleted
        name
        scientificName
        additionalNames
        category {
            id
            name
            code
            parent {
                id
                name
                code
                parent {
                    id
                    name
                    code
                    parent {
                        id
                        name
                        code
                    }
                }
            }
        }
        scopes(order: { sort: "ASC" }) {
            id
            name
        }
        additionalScopes
        description
        locations(order: { sort: "ASC" }) {
            id
            name
            parent {
                id
                name
                parent {
                    id
                    name
                    parent {
                        id
                        name
                        parent {
                            id
                            name
                        }
                    }
                }
            }
        }
        cultivation
        origin
        extraction
        production
        specialProcessing
        specialCoupling
        smell
        fireBehaviorAdditions
        durability
        durabilityBKI
        durabilityBNB
        additionalColorInformation
        properties(order: { sort: "ASC" }) {
            id
            name
            image
            color
            type {
                id
                sort
                name
                category
                allowMultiple
            }
        }
        colors(order: { sort: "ASC" }) {
            id
            name
            color
        }
        localEnvironmentRisk
        ecologicalBalanceReference
        recyclability
        ecologyAdditionalInformation
        images {
            id
            caption
            type
            filePath {
                id
                small
                large
                original
            }
        }
        mechanic
        thermodynamic
        resistance
        usageTechnology
        acoustic
        sponsorBUW
        sponsorKIT
        sponsorMSA
        signatureBUW
        signatureKIT
        signatureMSA
        copyrightImages
        copyrightGraphics
    }
`;
export const MaterialKeyFiguresResolverDataFragmentDoc = gql`
    fragment MaterialKeyFiguresResolverData on Material {
        keyFigures(order: { keyFigure_category_sort: "ASC", keyFigure_sort: "ASC" }) {
            id
            lowerValue
            upperValue
            keyFigure {
                id
                category {
                    id
                    name
                    sort
                    formArea
                }
                name
                symbol
                unit
                type
            }
        }
    }
`;
export const MaterialResolverDataFragmentDoc = gql`
    fragment MaterialResolverData on Material {
        ...MaterialData
        ...MaterialKeyFiguresResolverData
    }
    ${MaterialDataFragmentDoc}
    ${MaterialKeyFiguresResolverDataFragmentDoc}
`;
export const RevisionDetailDataFragmentDoc = gql`
    fragment RevisionDetailData on Revision {
        id
        createdAt
        description
        author {
            id
            firstName
            lastName
            email
        }
        reviewer {
            id
            firstName
            lastName
            email
        }
        applied
        changes {
            id
            field
            value
            originalValue
            applied
            rejected
        }
    }
`;
export const CreateGroupDocument = gql`
    mutation CreateGroup($group: createGroupWithTempIdInput!, $newUsers: [createUserWithTempIdInput!]!) {
        createMultipleGroups(groups: [$group]) {
            ...GroupDetailsFragment
        }
        createMultipleUsers(users: $newUsers) {
            id
        }
    }
    ${GroupDetailsFragmentFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class CreateGroupGQL extends Apollo.Mutation<CreateGroupMutation, CreateGroupMutationVariables> {
    document = CreateGroupDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GroupDetailsDocument = gql`
    query groupDetails($id: ID!) {
        group(id: $id) {
            ...GroupDetailsFragment
        }
    }
    ${GroupDetailsFragmentFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class GroupDetailsGQL extends Apollo.Query<GroupDetailsQuery, GroupDetailsQueryVariables> {
    document = GroupDetailsDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GroupsDocument = gql`
    query groups($afterCursor: String, $first: Int) {
        groups(after: $afterCursor, first: $first) {
            totalCount
            edges {
                node {
                    id
                    name
                    users {
                        totalCount
                    }
                }
            }
            pageInfo {
                hasNextPage
                endCursor
                hasPreviousPage
                startCursor
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class GroupsGQL extends Apollo.Query<GroupsQuery, GroupsQueryVariables> {
    document = GroupsDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateGroupDocument = gql`
    mutation UpdateGroup($group: updateGroupInput!, $newUsers: [createUserWithTempIdInput!]!) {
        updateGroup(input: $group) {
            group {
                ...GroupDetailsFragment
            }
        }
        createMultipleUsers(users: $newUsers) {
            id
        }
    }
    ${GroupDetailsFragmentFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class UpdateGroupGQL extends Apollo.Mutation<UpdateGroupMutation, UpdateGroupMutationVariables> {
    document = UpdateGroupDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UsersDocument = gql`
    query Users($afterCursor: String, $first: Int) {
        users(after: $afterCursor, first: $first) {
            totalCount
            edges {
                node {
                    id
                    username
                    firstName
                    lastName
                    groups {
                        edges {
                            node {
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class UsersGQL extends Apollo.Query<UsersQuery, UsersQueryVariables> {
    document = UsersDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const AdminMaterialGroupsDocument = gql`
    query AdminMaterialGroups {
        materialGroups(exists: { parent: false }, order: { sort: "ASC" }) {
            id
            name
            sort
            parent {
                id
            }
            children {
                id
                name
                sort
                parent {
                    id
                }
                children {
                    id
                    name
                    sort
                    parent {
                        id
                    }
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class AdminMaterialGroupsGQL extends Apollo.Query<AdminMaterialGroupsQuery, AdminMaterialGroupsQueryVariables> {
    document = AdminMaterialGroupsDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateMaterialGroupDocument = gql`
    mutation CreateMaterialGroup($input: createMaterialGroupInput!) {
        createMaterialGroup(input: $input) {
            materialGroup {
                name
                id
                sort
                parent {
                    id
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CreateMaterialGroupGQL extends Apollo.Mutation<
    CreateMaterialGroupMutation,
    CreateMaterialGroupMutationVariables
> {
    document = CreateMaterialGroupDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateMaterialGroupDocument = gql`
    mutation UpdateMaterialGroup($input: updateMaterialGroupInput!) {
        updateMaterialGroup(input: $input) {
            materialGroup {
                name
                id
                sort
                children {
                    id
                    name
                    sort
                    parent {
                        id
                    }
                    children {
                        id
                        name
                        sort
                    }
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class UpdateMaterialGroupGQL extends Apollo.Mutation<
    UpdateMaterialGroupMutation,
    UpdateMaterialGroupMutationVariables
> {
    document = UpdateMaterialGroupDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteMaterialGroupDocument = gql`
    mutation DeleteMaterialGroup($input: deleteMaterialGroupInput!) {
        deleteMaterialGroup(input: $input) {
            materialGroup {
                id
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class DeleteMaterialGroupGQL extends Apollo.Mutation<
    DeleteMaterialGroupMutation,
    DeleteMaterialGroupMutationVariables
> {
    document = DeleteMaterialGroupDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateColorDocument = gql`
    mutation CreateColor($input: createColorInput!) {
        createColor(input: $input) {
            color {
                ...ColorData
            }
        }
    }
    ${ColorDataFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class CreateColorGQL extends Apollo.Mutation<CreateColorMutation, CreateColorMutationVariables> {
    document = CreateColorDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateColorDocument = gql`
    mutation updateColor($input: updateColorInput!) {
        updateColor(input: $input) {
            color {
                ...ColorData
            }
        }
    }
    ${ColorDataFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class UpdateColorGQL extends Apollo.Mutation<UpdateColorMutation, UpdateColorMutationVariables> {
    document = UpdateColorDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteColorDocument = gql`
    mutation deleteColor($input: deleteColorInput!) {
        deleteColor(input: $input) {
            color {
                id
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class DeleteColorGQL extends Apollo.Mutation<DeleteColorMutation, DeleteColorMutationVariables> {
    document = DeleteColorDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ColorsDocument = gql`
    query Colors {
        colors(order: { sort: "ASC" }) {
            ...ColorData
        }
    }
    ${ColorDataFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class ColorsGQL extends Apollo.Query<ColorsQuery, ColorsQueryVariables> {
    document = ColorsDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const LocationsDocument = gql`
    query Locations {
        locations(order: { sort: "ASC" }, exists: { parent: false }) {
            id
            name
            sort
            parent {
                id
            }
            children {
                id
                name
                sort
                parent {
                    id
                }
                children {
                    id
                    name
                    sort
                    parent {
                        id
                    }
                    children {
                        id
                        name
                        sort
                        parent {
                            id
                        }
                        children {
                            id
                            name
                            sort
                            parent {
                                id
                            }
                        }
                    }
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class LocationsGQL extends Apollo.Query<LocationsQuery, LocationsQueryVariables> {
    document = LocationsDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateLocationDocument = gql`
    mutation CreateLocation($input: createLocationInput!) {
        createLocation(input: $input) {
            location {
                name
                id
                sort
                parent {
                    id
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CreateLocationGQL extends Apollo.Mutation<CreateLocationMutation, CreateLocationMutationVariables> {
    document = CreateLocationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateLocationDocument = gql`
    mutation UpdateLocation($input: updateLocationInput!) {
        updateLocation(input: $input) {
            location {
                name
                id
                sort
                children {
                    id
                    name
                    parent {
                        id
                    }
                    children {
                        id
                        name
                    }
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class UpdateLocationGQL extends Apollo.Mutation<UpdateLocationMutation, UpdateLocationMutationVariables> {
    document = UpdateLocationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteLocationDocument = gql`
    mutation DeleteLocation($input: deleteLocationInput!) {
        deleteLocation(input: $input) {
            location {
                id
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class DeleteLocationGQL extends Apollo.Mutation<DeleteLocationMutation, DeleteLocationMutationVariables> {
    document = DeleteLocationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateMaterialScopeDocument = gql`
    mutation CreateMaterialScope($input: createMaterialScopeInput!) {
        createMaterialScope(input: $input) {
            materialScope {
                ...MaterialScopeData
            }
        }
    }
    ${MaterialScopeDataFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class CreateMaterialScopeGQL extends Apollo.Mutation<
    CreateMaterialScopeMutation,
    CreateMaterialScopeMutationVariables
> {
    document = CreateMaterialScopeDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateMaterialScopeDocument = gql`
    mutation UpdateMaterialScope($input: updateMaterialScopeInput!) {
        updateMaterialScope(input: $input) {
            materialScope {
                ...MaterialScopeData
            }
        }
    }
    ${MaterialScopeDataFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class UpdateMaterialScopeGQL extends Apollo.Mutation<
    UpdateMaterialScopeMutation,
    UpdateMaterialScopeMutationVariables
> {
    document = UpdateMaterialScopeDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteMaterialScopeDocument = gql`
    mutation DeleteMaterialScope($input: deleteMaterialScopeInput!) {
        deleteMaterialScope(input: $input) {
            materialScope {
                id
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class DeleteMaterialScopeGQL extends Apollo.Mutation<
    DeleteMaterialScopeMutation,
    DeleteMaterialScopeMutationVariables
> {
    document = DeleteMaterialScopeDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreatePropertyDocument = gql`
    mutation CreateProperty($input: createPropertyInput!) {
        createProperty(input: $input) {
            property {
                ...PropertyData
            }
        }
    }
    ${PropertyDataFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class CreatePropertyGQL extends Apollo.Mutation<CreatePropertyMutation, CreatePropertyMutationVariables> {
    document = CreatePropertyDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdatePropertyDocument = gql`
    mutation updateProperty($input: updatePropertyInput!) {
        updateProperty(input: $input) {
            property {
                ...PropertyData
            }
        }
    }
    ${PropertyDataFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class UpdatePropertyGQL extends Apollo.Mutation<UpdatePropertyMutation, UpdatePropertyMutationVariables> {
    document = UpdatePropertyDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeletePropertyDocument = gql`
    mutation deleteProperty($input: deletePropertyInput!) {
        deleteProperty(input: $input) {
            property {
                id
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class DeletePropertyGQL extends Apollo.Mutation<DeletePropertyMutation, DeletePropertyMutationVariables> {
    document = DeletePropertyDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const PropertyTypeDocument = gql`
    query PropertyType($id: ID!) {
        propertyType(id: $id) {
            __typename
            ...PropertyTypeData
        }
    }
    ${PropertyTypeDataFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class PropertyTypeGQL extends Apollo.Query<PropertyTypeQuery, PropertyTypeQueryVariables> {
    document = PropertyTypeDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const PropertyTypesDocument = gql`
    query PropertyTypes {
        propertyTypes(order: { sort: "ASC" }) {
            id
            name
            category
            properties(order: { sort: "ASC" }) {
                id
                name
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class PropertyTypesGQL extends Apollo.Query<PropertyTypesQuery, PropertyTypesQueryVariables> {
    document = PropertyTypesDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const AdminChangePasswordDocument = gql`
    mutation AdminChangePassword($newPassword: String!, $userId: ID!) {
        adminChangePasswordUser(input: { newPassword: $newPassword, id: $userId }) {
            user {
                id
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class AdminChangePasswordGQL extends Apollo.Mutation<
    AdminChangePasswordMutation,
    AdminChangePasswordMutationVariables
> {
    document = AdminChangePasswordDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CountUsersByMailDocument = gql`
    query countUsersByMail($email: String) {
        users(email: $email) {
            totalCount
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CountUsersByMailGQL extends Apollo.Query<CountUsersByMailQuery, CountUsersByMailQueryVariables> {
    document = CountUsersByMailDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateUserDocument = gql`
    mutation CreateUser($input: createUserInput!) {
        createUser(input: $input) {
            user {
                id
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CreateUserGQL extends Apollo.Mutation<CreateUserMutation, CreateUserMutationVariables> {
    document = CreateUserDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateUserDocument = gql`
    mutation updateUser($input: updateUserInput!) {
        updateUser(input: $input) {
            user {
                id
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    document = UpdateUserDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UserListDocument = gql`
    query UserList {
        users {
            edges {
                node {
                    id
                    email
                    firstName
                    lastName
                    roles
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class UserListGQL extends Apollo.Query<UserListQuery, UserListQueryVariables> {
    document = UserListDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UserDocument = gql`
    query User($id: ID!) {
        user(id: $id) {
            id
            email
            roles
            firstName
            lastName
            organization
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class UserGQL extends Apollo.Query<UserQuery, UserQueryVariables> {
    document = UserDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const RemoveUserDocument = gql`
    mutation removeUser($id: ID!) {
        removeUser(input: { id: $id }) {
            user {
                id
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class RemoveUserGQL extends Apollo.Mutation<RemoveUserMutation, RemoveUserMutationVariables> {
    document = RemoveUserDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CurrentUserDocument = gql`
    query currentUser {
        meUser {
            id
            username
            email
            roles
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CurrentUserGQL extends Apollo.Query<CurrentUserQuery, CurrentUserQueryVariables> {
    document = CurrentUserDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const FormRulesDocument = gql`
    query FormRules($materialGroup: ID!) {
        materialGroup(id: $materialGroup) {
            id
            ...RuleConfiguration
            parent {
                id
                ...RuleConfiguration
                parent {
                    id
                    ...RuleConfiguration
                    parent {
                        id
                        ...RuleConfiguration
                        parent {
                            id
                            ...RuleConfiguration
                            parent {
                                id
                                ...RuleConfiguration
                            }
                        }
                    }
                }
            }
        }
    }
    ${RuleConfigurationFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class FormRulesGQL extends Apollo.Query<FormRulesQuery, FormRulesQueryVariables> {
    document = FormRulesDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MaterialScopeNameDocument = gql`
    query MaterialScopeName($id: ID!) {
        materialScope(id: $id) {
            id
            name
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class MaterialScopeNameGQL extends Apollo.Query<MaterialScopeNameQuery, MaterialScopeNameQueryVariables> {
    document = MaterialScopeNameDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ColorNameDocument = gql`
    query ColorName($id: ID!) {
        color(id: $id) {
            id
            name
            color
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class ColorNameGQL extends Apollo.Query<ColorNameQuery, ColorNameQueryVariables> {
    document = ColorNameDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const KeyFigureMetadataDocument = gql`
    query KeyFigureMetadata($id: ID!) {
        keyFigure(id: $id) {
            id
            name
            symbol
            unit
            type
            category {
                id
                name
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class KeyFigureMetadataGQL extends Apollo.Query<KeyFigureMetadataQuery, KeyFigureMetadataQueryVariables> {
    document = KeyFigureMetadataDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const PropertyMetadataDocument = gql`
    query PropertyMetadata($id: ID!) {
        property(id: $id) {
            id
            name
            image
            type {
                id
                name
                category
                allowMultiple
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class PropertyMetadataGQL extends Apollo.Query<PropertyMetadataQuery, PropertyMetadataQueryVariables> {
    document = PropertyMetadataDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateMaterialDocument = gql`
    mutation CreateMaterial(
        $material: createMaterialWithTempIdInput!
        $newKeyFigures: [createKeyFigureValueWithTempIdInput!]!
        $updatedImages: [updateMaterialImageInput!]!
    ) {
        createMultipleMaterials(materials: [$material]) {
            __typename
            ...MaterialResolverData
        }
        updateMultipleMaterialImages(materialImages: $updatedImages) {
            id
            caption
        }
        createMultipleKeyFigureValues(keyFigureValues: $newKeyFigures) {
            id
        }
    }
    ${MaterialResolverDataFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class CreateMaterialGQL extends Apollo.Mutation<CreateMaterialMutation, CreateMaterialMutationVariables> {
    document = CreateMaterialDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateMaterialDocument = gql`
    mutation UpdateMaterial(
        $material: updateMaterialInput!
        $newKeyFigures: [createKeyFigureValueWithTempIdInput!]!
        $updatedKeyFigures: [updateKeyFigureValueInput!]!
        $deletedKeyFigures: [ID!]!
        $updatedImages: [updateMaterialImageInput!]!
    ) {
        updateMaterial(input: $material) {
            material {
                __typename
                id
            }
        }
        updateMultipleKeyFigureValues(keyFigureValues: $updatedKeyFigures) {
            id
        }
        createMultipleKeyFigureValues(keyFigureValues: $newKeyFigures) {
            id
        }
        updateMultipleMaterialImages(materialImages: $updatedImages) {
            id
            caption
        }
        deleteMultipleKeyFigureValues(ids: $deletedKeyFigures)
    }
`;

@Injectable({
    providedIn: 'root',
})
export class UpdateMaterialGQL extends Apollo.Mutation<UpdateMaterialMutation, UpdateMaterialMutationVariables> {
    document = UpdateMaterialDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateRevisionDocument = gql`
    mutation CreateRevision($description: String!, $materialId: String!, $changes: [createChangeWithTempIdInput!]!) {
        createMultipleRevisions(
            revisions: [{ tmpId: "newRevision", changes: [], material: $materialId, description: $description }]
        ) {
            id
        }
        createMultipleChanges(changes: $changes) {
            id
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CreateRevisionGQL extends Apollo.Mutation<CreateRevisionMutation, CreateRevisionMutationVariables> {
    document = CreateRevisionDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UploadMaterialImageDocument = gql`
    mutation UploadMaterialImage($file: Upload!, $type: String!) {
        uploadMaterialImage(input: { file: $file, type: $type }) {
            materialImage {
                id
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class UploadMaterialImageGQL extends Apollo.Mutation<
    UploadMaterialImageMutation,
    UploadMaterialImageMutationVariables
> {
    document = UploadMaterialImageDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MarkForDeletionMaterialDocument = gql`
    mutation MarkForDeletionMaterial($id: ID!, $reset: Boolean) {
        markForDeletionMaterial(input: { id: $id, reset: $reset }) {
            material {
                id
                markedForDeletionAt
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class MarkForDeletionMaterialGQL extends Apollo.Mutation<
    MarkForDeletionMaterialMutation,
    MarkForDeletionMaterialMutationVariables
> {
    document = MarkForDeletionMaterialDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MaterialApplicationsDocument = gql`
    query MaterialApplications {
        materialScopes(order: { sort: "ASC" }) {
            id
            name
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class MaterialApplicationsGQL extends Apollo.Query<
    MaterialApplicationsQuery,
    MaterialApplicationsQueryVariables
> {
    document = MaterialApplicationsDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MaterialGroupsDocument = gql`
    query MaterialGroups {
        materialGroups(exists: { parent: false }, order: { sort: "ASC" }) {
            id
            name
            children {
                id
                name
                parent {
                    id
                }
                children {
                    id
                    name
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class MaterialGroupsGQL extends Apollo.Query<MaterialGroupsQuery, MaterialGroupsQueryVariables> {
    document = MaterialGroupsDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MaterialLocationsDocument = gql`
    query MaterialLocations {
        locations(order: { sort: "ASC" }, exists: { parent: false }) {
            id
            name
            parent {
                id
            }
            children {
                id
                name
                parent {
                    id
                }
                children {
                    id
                    name
                    parent {
                        id
                    }
                    children {
                        id
                        name
                        parent {
                            id
                        }
                        children {
                            id
                            name
                            parent {
                                id
                            }
                        }
                    }
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class MaterialLocationsGQL extends Apollo.Query<MaterialLocationsQuery, MaterialLocationsQueryVariables> {
    document = MaterialLocationsDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MaterialColorsDocument = gql`
    query MaterialColors {
        colors(order: { sort: "ASC" }) {
            id
            name
            color
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class MaterialColorsGQL extends Apollo.Query<MaterialColorsQuery, MaterialColorsQueryVariables> {
    document = MaterialColorsDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const KeyFigureCategoriesDocument = gql`
    query KeyFigureCategories {
        keyFigureCategories(order: { sort: "ASC" }) {
            id
            name
            formArea
            additionalInformation
            keyFigures(order: { sort: "ASC" }) {
                id
                name
                symbol
                unit
                type
                placeholderText
                category {
                    id
                    formArea
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class KeyFigureCategoriesGQL extends Apollo.Query<KeyFigureCategoriesQuery, KeyFigureCategoriesQueryVariables> {
    document = KeyFigureCategoriesDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MaterialPropertyTypesDocument = gql`
    query MaterialPropertyTypes {
        propertyTypes(order: { sort: "ASC" }) {
            id
            name
            category
            allowMultiple
            defaultFormRule
            properties(order: { sort: "ASC" }) {
                id
                name
                image
                color
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class MaterialPropertyTypesGQL extends Apollo.Query<
    MaterialPropertyTypesQuery,
    MaterialPropertyTypesQueryVariables
> {
    document = MaterialPropertyTypesDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MaterialsDocument = gql`
    query Materials(
        $first: Int
        $afterCursor: String
        $id: Int
        $name: String
        $category: String
        $active: Boolean
        $isDeleted: Boolean!
    ) {
        materials(
            first: $first
            after: $afterCursor
            id: $id
            name: $name
            category: $category
            active: $active
            isDeleted: $isDeleted
        ) {
            edges {
                node {
                    id
                    active
                    name
                    updatedAt
                    author {
                        id
                        firstName
                        lastName
                        organization
                    }
                    lastEditor {
                        id
                        firstName
                        lastName
                        organization
                    }
                    category {
                        id
                        name
                        code
                        parent {
                            id
                            name
                            code
                            parent {
                                id
                                name
                                code
                            }
                        }
                    }
                }
            }
            totalCount
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class MaterialsGQL extends Apollo.Query<MaterialsQuery, MaterialsQueryVariables> {
    document = MaterialsDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MaterialDocument = gql`
    query Material($id: ID!) {
        material(id: $id) {
            __typename
            ...MaterialData
        }
    }
    ${MaterialDataFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class MaterialGQL extends Apollo.Query<MaterialQuery, MaterialQueryVariables> {
    document = MaterialDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const KeyFiguresByMaterialidDocument = gql`
    query KeyFiguresByMaterialid($id: ID!) {
        material(id: $id) {
            __typename
            ...MaterialKeyFiguresResolverData
        }
    }
    ${MaterialKeyFiguresResolverDataFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class KeyFiguresByMaterialidGQL extends Apollo.Query<
    KeyFiguresByMaterialidQuery,
    KeyFiguresByMaterialidQueryVariables
> {
    document = KeyFiguresByMaterialidDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetMaterialScopeByIdDocument = gql`
    query GetMaterialScopeById($id: ID!) {
        materialScope(id: $id) {
            id
            name
            __typename
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class GetMaterialScopeByIdGQL extends Apollo.Query<
    GetMaterialScopeByIdQuery,
    GetMaterialScopeByIdQueryVariables
> {
    document = GetMaterialScopeByIdDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetLocationByIdDocument = gql`
    query GetLocationById($id: ID!) {
        location(id: $id) {
            id
            name
            parent {
                id
                name
                parent {
                    id
                    name
                    parent {
                        id
                        name
                        parent {
                            id
                            name
                            __typename
                        }
                        __typename
                    }
                    __typename
                }
                __typename
            }
            __typename
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class GetLocationByIdGQL extends Apollo.Query<GetLocationByIdQuery, GetLocationByIdQueryVariables> {
    document = GetLocationByIdDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetPropertyByIdDocument = gql`
    query GetPropertyById($id: ID!) {
        property(id: $id) {
            id
            name
            image
            color
            type {
                id
                sort
                name
                category
                allowMultiple
            }
            __typename
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class GetPropertyByIdGQL extends Apollo.Query<GetPropertyByIdQuery, GetPropertyByIdQueryVariables> {
    document = GetPropertyByIdDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetColorByIdDocument = gql`
    query GetColorById($id: ID!) {
        color(id: $id) {
            id
            name
            color
            __typename
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class GetColorByIdGQL extends Apollo.Query<GetColorByIdQuery, GetColorByIdQueryVariables> {
    document = GetColorByIdDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetImageByIdDocument = gql`
    query GetImageById($id: ID!) {
        materialImage(id: $id) {
            id
            caption
            type
            filePath {
                id
                small
                large
                original
            }
            __typename
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class GetImageByIdGQL extends Apollo.Query<GetImageByIdQuery, GetImageByIdQueryVariables> {
    document = GetImageByIdDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MaterialRevisionsDocument = gql`
    query MaterialRevisions($materialId: ID!, $afterCursor: String, $first: Int) {
        material(id: $materialId) {
            id
            revisions(after: $afterCursor, first: $first, exists: { applied: true }) {
                edges {
                    node {
                        id
                        createdAt
                        description
                        author {
                            id
                            firstName
                            lastName
                            email
                        }
                        reviewer {
                            id
                            firstName
                            lastName
                            email
                        }
                        applied
                    }
                }
                totalCount
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class MaterialRevisionsGQL extends Apollo.Query<MaterialRevisionsQuery, MaterialRevisionsQueryVariables> {
    document = MaterialRevisionsDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MaterialRevisionDocument = gql`
    query MaterialRevision($id: ID!) {
        revision(id: $id) {
            __typename
            ...RevisionDetailData
        }
    }
    ${RevisionDetailDataFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class MaterialRevisionGQL extends Apollo.Query<MaterialRevisionQuery, MaterialRevisionQueryVariables> {
    document = MaterialRevisionDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ReviewRevisionDocument = gql`
    mutation ReviewRevision($revisionId: ID!, $acceptedChanges: [ID!]!, $declinedChanges: [ID!]!) {
        reviewRevision(input: { id: $revisionId, accept: $acceptedChanges, decline: $declinedChanges }) {
            revision {
                __typename
                ...RevisionDetailData
                material {
                    __typename
                    ...MaterialResolverData
                }
            }
        }
    }
    ${RevisionDetailDataFragmentDoc}
    ${MaterialResolverDataFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class ReviewRevisionGQL extends Apollo.Mutation<ReviewRevisionMutation, ReviewRevisionMutationVariables> {
    document = ReviewRevisionDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ChangePasswordDocument = gql`
    mutation ChangePassword($currentPassword: String!, $newPassword: String!, $userId: ID) {
        changePasswordUser(input: { currentPassword: $currentPassword, newPassword: $newPassword }) {
            user {
                id
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class ChangePasswordGQL extends Apollo.Mutation<ChangePasswordMutation, ChangePasswordMutationVariables> {
    document = ChangePasswordDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
